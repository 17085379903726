import * as pdfFonts from "pdfmake/build/vfs_fonts"

import { ActivatedRoute } from "@angular/router"
import { Component } from "@angular/core"
import { OnInit } from "@angular/core"
import { PipeFmt } from "app/pipes/fmt"
import type { RetrieveResponse as Report } from "app/services/report"
import { ServiceReport } from "app/services/report"
import { ServiceReportFile } from "app/services/report-file"
import { ServiceReportImage } from "app/services/report-image"
import { ServiceUser } from "app/services/user"
import type { TDocumentDefinitions } from "pdfmake/interfaces"
import { ToastrService } from "ngx-toastr"
import { createPdf } from "pdfmake/build/pdfmake"

@Component({ providers: [PipeFmt], styleUrls: ["index.scss"], templateUrl: "index.html" })
export class RouteReportComponent implements OnInit {
  loading = false

  report: Report | null = null

  user = this.serviceUser.current.getValue()

  constructor(
    private fmt: PipeFmt,
    private route: ActivatedRoute,
    private serviceReport: ServiceReport,
    private serviceReportFile: ServiceReportFile,
    private serviceReportImage: ServiceReportImage,
    private serviceUser: ServiceUser,
    private toastr: ToastrService,
  ) {}

  ngOnInit() {
    this.route.params.subscribe({
      next: ({ reportId: id }) => {
        this.loading = true
        this.serviceReport.retrieve(id).subscribe({
          next: (resp) => {
            this.loading = false
            this.report = resp
          },
          error: (resp) => {
            this.loading = false
            this.toastr.error("We will investigate the problem.", `${resp.statusText}`)
            throw resp
          },
        })
      },
    })
  }

  onFilesClick(action: "download", file: Report["files"][number]) {
    if (action === "download") {
      file.$loading = true
      this.serviceReportFile.download(file.id).subscribe({
        next: () => {
          file.$loading = false
        },
        error: (resp) => {
          file.$loading = false
          this.toastr.error("We will investigate the problem.", `${resp.statusText}`)
          throw resp
        },
      })
    }
  }

  onImagesClick(action: "download" | "view", image: Report["images"][number]) {
    if (action === "download") {
      image.$loading = true
      this.serviceReportImage.download(image.id).subscribe({
        next: () => {
          image.$loading = false
        },
        error: (resp) => {
          image.$loading = false
          this.toastr.error("We will investigate the problem.", `${resp.statusText}`)
          throw resp
        },
      })
    }
    if (action === "view") {
      image.$loading = true
      this.serviceReportImage.view(image.id).subscribe({
        next: () => {
          image.$loading = false
        },
        error: (resp) => {
          image.$loading = false
          this.toastr.error("We will investigate the problem.", `${resp.statusText}`)
          throw resp
        },
      })
    }
  }

  onReportClick(action: "pdf-download" | "pdf-view") {
    if (this.report) {
      this.pdfMake(this.report).then(({ fname, pdf }) => {
        if (action === "pdf-download") {
          pdf.download(fname)
        }
        if (action === "pdf-view") {
          pdf.open()
        }
      })
    }
  }

  async pdfMake(report: Report) {
    const { fmt, user } = this
    const {
      id,
      close_message,
      description,
      images,
      patient: {
        first_name, //
        last_name,
        birth_date,
      },
      practice: {
        picture = "",
        name,
        location: {
          street1, //
          street2,
          city,
          state,
          zip_code,
        },
        phone,
      },
      sent_at,
      signing_provider: {
        id: provider_id,
        signature: provider_signature,
        first_name: provider_first_name,
        last_name: provider_last_name,
      },
      summary,
    } = report
    const content: TDocumentDefinitions["content"] = []
    content.push({
      columns: [
        {
          image: fmt.transform(picture, "b64-img"),
          width: 80,
        },
        {
          ul: [
            {
              text: fmt.transform(name),
              style: {
                bold: true,
                fontSize: 12,
              },
            },
            {
              text: fmt.transform(street1),
            },
            {
              text: fmt.transform(street2),
            },
            {
              text: `${fmt.transform(city)}, ${fmt.transform(state)} ${fmt.transform(zip_code)}`,
            },
            {
              text: fmt.transform(phone, "phone-number"),
            },
          ],
          type: "none",
          width: "*",
        },
        {
          ul: [
            {
              text: "Patient Name",
              style: {
                bold: true,
              },
            },
            {
              text: `${fmt.transform(last_name)}, ${fmt.transform(first_name)}`,
            },
            {
              text: "Patient DOB",
              margin: [0, 6, 0, 0],
              style: {
                bold: true,
              },
            },
            {
              text: fmt.transform(birth_date, "date"),
            },
          ],
          style: {
            alignment: "right",
          },
          type: "none",
          width: "auto",
        },
      ],
      columnGap: 6,
    })
    content.push({
      text: [
        {
          text: "Published: ",
          style: {
            bold: true,
            fontSize: 12,
          },
        },
        {
          text: fmt.transform(sent_at, "date"),
        },
      ],
      margin: [0, 5],
    })
    if (summary) {
      content.push({
        text: [
          {
            text: "Re: ",
            style: {
              fontSize: 12,
            },
          },
          {
            text: fmt.transform(summary),
          },
        ],
        margin: [0, 5],
        style: {
          bold: true,
        },
      })
    }
    if (description) {
      content.push({
        text: fmt.transform(description),
        margin: [0, 5],
        style: {
          preserveLeadingSpaces: true, // preserveTrailingSpaces: true,
        },
      })
    }
    if (images.length > 0) {
      await Promise.all(images.map(async ({ name: text, picture }) => ({ text, image: await picture.dataurl() }))).then(
        (images) => {
          images
            .reduce((memo, item, idx) => {
              if (idx % 3 === 0) {
                memo.push([])
              }
              memo[memo.length - 1].push(item)
              return memo
            }, <typeof images[number][][]>[])
            .forEach((row) => {
              content.push({
                columns: row.map(({ text, image }) => ({
                  stack: [
                    {
                      image: fmt.transform(image, "b64-img"),
                      width: 160,
                    },
                    {
                      text: fmt.transform(text),
                      maxHeight: 26,
                    },
                  ],
                  width: "*",
                  unbreakable: true,
                })),
                margin: [0, 5],
                style: {
                  alignment: "center",
                },
              })
            })
        },
      )
    }
    if (close_message) {
      content.push({
        text: fmt.transform(close_message),
        margin: [0, 5],
        style: {
          preserveLeadingSpaces: true, // preserveTrailingSpaces: true,
        },
      })
    }
    if (provider_id) {
      content.push({
        image: fmt.transform(provider_signature, "b64-img"),
        fit: [300, 155],
        margin: [0, 5],
      })
      content.push({
        columns: [
          {
            text: `${fmt.transform(provider_last_name)}, ${fmt.transform(provider_first_name)}`,
            width: 300,
            style: {
              alignment: "center",
            },
          },
        ],
        margin: [0, 5],
      })
    }
    const title = `Referral Report - ${first_name} ${last_name}`
    return {
      fname: `${title}.pdf`,
      pdf: createPdf(
        {
          content,
          defaultStyle: { fontSize: 11 },
          info: { author: "DentalEMR Inc", title, keywords: `report-id=${id} user-id=${user.id}` },
          pageOrientation: "portrait",
          pageSize: "A4",
        },
        undefined,
        undefined,
        pdfFonts.pdfMake.vfs,
      ),
    }
  }

  trackById(_index: number, item: any) {
    return item.id
  }
}
