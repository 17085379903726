<div class="container m-t-lg">
  <div class="row">
    <div class="col-md-6 col-md-offset-3 well well-z m-b-0">
      <div class="sk-chase" *ngIf="loading">
        <div class="sk-chase-dot"></div>
        <div class="sk-chase-dot"></div>
        <div class="sk-chase-dot"></div>
        <div class="sk-chase-dot"></div>
        <div class="sk-chase-dot"></div>
        <div class="sk-chase-dot"></div>
      </div>
      <ng-container *ngIf="params.practice">
        <img class="img-thumbnail" [attr.src]="params.practice.picture.url | async | trust" />
        <h4 class="text-center">{{ params.practice.name }} Referrals Portal</h4>
      </ng-container>
      <h3 class="text-center">Set your password</h3>
      <h4 class="text-center">{{ params.name }}</h4>
      <form [formGroup]="formGroup" (ngSubmit)="onSubmit()">
        <div class="form-group has-feedback" *ngIf="params.email">
          <label class="control-label">Email</label>
          <input class="form-control" [value]="params.email" type="email" [readonly]="true" />
          <span class="help-block">
            This is your username, please copy it down for future access.
          </span>
        </div>
        <div class="form-group has-feedback" *ngIf="formGroup | get:'password' as f" [ngClass]="{'has-error': f.invalid}">
          <label class="control-label">Password</label>
          <input class="form-control" [formControl]="f" [type]="formGroup.get('password_txt')?.value ? 'text' : 'password'" />
          <span class="icon icon-warning form-control-feedback" *ngIf="f.invalid" [tooltip]="errors">
            <ng-template #errors>
              <div *ngIf="f.hasError('pattern')">
                Must be at least 8 characters long and contain at least 1 uppercase, lowercase, number, and special character.
              </div>
              <div *ngIf="f.hasError('required')">Required</div>
              <div *ngFor="let e of f.getError('server')">{{ e }}</div>
            </ng-template>
          </span>
        </div>
        <div class="form-group has-feedback" *ngIf="formGroup | get:'password_rep' as f" [ngClass]="{'has-error': f.invalid}">
          <label class="control-label">Repeat Password</label>
          <input class="form-control" [formControl]="f" type="password" />
          <span class="icon icon-warning form-control-feedback" *ngIf="f.invalid" [tooltip]="errors">
            <ng-template #errors>
              <div *ngIf="f.hasError('mismatch')">Passwords do not match.</div>
            </ng-template>
          </span>
        </div>
        <div class="form-group" *ngIf="formGroup | get:'password_txt' as f">
          <div class="checkbox custom-control custom-checkbox">
            <label>
              <input type="checkbox" [formControl]="f" />
              <span class="custom-control-indicator"></span>
              Show Password
            </label>
          </div>
        </div>
        <ng-container *ngIf="formGroup | get:'id' as f">
          <ng-container *ngIf="formGroup | get:'token' as g">
            <div class="alert alert-danger m-b text-center" *ngIf="f.invalid || g.invalid">
              Invalid url for setting user password.
            </div>
          </ng-container>
        </ng-container>
        <button type="submit" class="btn btn-primary btn-lg btn-block">Submit</button>
        <div class="alert alert-danger" *ngIf="formGroup.hasError('server') && formGroup as f">
          <div *ngFor="let e of f.getError('server')">{{ e }}</div>
        </div>
        <h6 class="text-center m-b-0">
          Powered by
          <a href="//www.dentalemr.com" target="_blank">Dental EMR</a>
        </h6>
      </form>
    </div>
  </div>
</div>
