import * as dayjs from "dayjs"

import { HttpClient } from "@angular/common/http"
import { HttpParams } from "@angular/common/http"
import type { HttpParamsOptions } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { Picture } from "app/picture.class"
import { ServiceUser } from "app/services/user"
import { map } from "rxjs/operators"

const api = "/api/v3/~referrals-external/referral"

@Injectable({ providedIn: "root" })
export class ServiceReferral {
  constructor(private http: HttpClient, private serviceUser: ServiceUser) {}

  list(params: HttpParamsOptions["fromObject"] = {}) {
    const user = this.serviceUser.current.getValue()
    type ApiResponse = Omit<ListResponse, "date" | "isFromOther"> & {
      date: string
    }
    return this.http.get<ApiResponse[]>(`${api}`, { params: new HttpParams({ fromObject: params }) }).pipe(
      map<ApiResponse[], ListResponse[]>((data) =>
        data.map(
          ({
            date, //
            ext_user,
            ...props
          }) => ({
            ...props,
            date: dayjs(date, "YYYY-MM-DD").toDate(),
            ext_user,
            isFromOther: ext_user.id !== user.id,
          }),
        ),
      ),
    )
  }

  create(data: any) {
    type ApiResponse = CreateResponse
    return this.http.post<ApiResponse>(`${api}`, data)
  }

  retrieve(id: number) {
    type ApiResponse = Omit<RetrieveResponse, "images"> & {
      images: {
        id: number
        archive_thumb: string | null
      }[]
    }
    return this.http.get<ApiResponse>(`${api}/${id}`).pipe(
      map<ApiResponse, RetrieveResponse>(
        ({
          images = [], //
          ...props
        }) => ({
          ...props,
          images: images.map(
            ({
              archive_thumb, //
              ...props
            }) => ({
              ...props,
              archive_thumb: new Picture(archive_thumb, this.http, "/assets/no-thumb.jpg"),
            }),
          ),
        }),
      ),
    )
  }

  update(data: any) {
    type ApiResponse = UpdateResponse
    return this.http.put<ApiResponse>(`${api}/${data.id}`, data)
  }

  choices(id: number | string) {
    type ApiResponse = ChoicesResponse
    return this.http.get<ApiResponse>(`${api}/${id}/choices`)
  }
}

export type ListResponse = {
  id: number
  date: Date
  is_completed: boolean
  ext_patient: { name: string }
  ext_practice: { id: number; name: string; phone: string }
  ext_user: { id: number; name: string; phone: string }
  referred_for: { description: string }
  reports: { exists: boolean; unread: boolean }
  isFromOther: boolean
}

export type CreateResponse = {
  id: number
}

export type RetrieveResponse = {
  id: number
  ext_practice: number
  ext_user: number
  images: {
    id: number
    archive_thumb: Picture
  }[]
}

export type UpdateResponse = {}

export type ChoicesResponse = {
  practices: {
    id: number
    name: string
    can_create_referrals_on_behalf_of_others: boolean
    users: {
      id: number
      name: string
    }[]
  }[]
}
