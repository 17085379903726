import { AppModule } from "app/app.module"
import { enableProdMode } from "@angular/core"
import { environment } from "environments/environment"
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic"

if (environment.production) {
  enableProdMode()
} else {
  console.groupCollapsed("environments/environment { environment }")
  console.log(JSON.stringify(environment, null, 2))
  console.groupEnd()
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err))
