<div class="container m-t-lg">
  <div class="row">
    <div class="col-md-6 col-md-offset-3 well well-z m-b-0">
      <div class="sk-wave" *ngIf="loading">
        <div class="sk-wave-rect"></div>
        <div class="sk-wave-rect"></div>
        <div class="sk-wave-rect"></div>
        <div class="sk-wave-rect"></div>
        <div class="sk-wave-rect"></div>
      </div>
      <img class="img-thumbnail" [attr.src]="practice.picture.url | async | trust" />
      <h3 class="text-center m-b">Reset your Password</h3>
      <form [formGroup]="formGroup" (ngSubmit)="onSubmit()">
        <div class="form-group has-feedback" *ngIf="formGroup.get('email') as f" [ngClass]="{'has-error': f.invalid}">
          <label class="control-label">Email</label>
          <input class="form-control" [formControl]="$any(f)" type="email" />
          <span class="icon icon-warning form-control-feedback" *ngIf="f.invalid" [tooltip]="errors">
            <ng-template #errors>
              <div *ngIf="f.hasError('email')">Invalid.</div>
              <div *ngIf="f.hasError('required')">Required.</div>
              <div *ngFor="let e of f.getError('server')">{{ e }}</div>
            </ng-template>
          </span>
        </div>
        <button type="submit" class="btn btn-primary btn-lg btn-block">Submit</button>
        <h6 class="text-center m-b-0">
          Powered by
          <a href="//www.dentalemr.com" target="_blank">Dental EMR</a>
        </h6>
      </form>
      <div class="alert alert-danger" *ngIf="formGroup.hasError('server') && formGroup as f">
        <div *ngFor="let e of f.getError('server')">{{ e }}</div>
      </div>
    </div>
  </div>
</div>
