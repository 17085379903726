<ng-container *ngIf="route.children.length === 0">
  <div class="dashhead">
    <div class="dashhead-titles">
      <h6 class="dashhead-subtitle">
        <span [hidden]="!loading">loading...</span>
        &nbsp;
      </h6>
      <h3 class="dashhead-title">
        Referral Reports
        <small></small>
      </h3>
    </div>
    <div class="dashhead-toolbar">
      <div class="dashhead-toolbar-item">
        <button class="btn btn-wide btn-default" type="button" (click)="list()">Reload</button>
      </div>
    </div>
  </div>
  <ul class="nav nav-bordered m-y">
    <li routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
      <a [routerLink]="['..']" class="p-x-md">
        <h4 class="m-y-0">General</h4>
      </a>
    </li>
    <li routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
      <a [routerLink]="['.']" class="p-x-md">
        <h4 class="m-y-0">Reports</h4>
      </a>
    </li>
  </ul>
  <div class="table-responsive m-t">
    <table class="table">
      <thead>
        <tr>
          <th></th>
          <th>Published</th>
          <th>Read At</th>
          <th>Images</th>
          <th>Files</th>
          <th>Summary</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let report of reports ; trackBy:trackById">
          <td>
            <code class="text-muted">{{ report.id }}</code>
          </td>
          <td>
            <span>{{ report.sent_at | date:"MM/dd/yyyy" }}</span>
          </td>
          <td>
            <span>{{ report.read_at | date:"MM/dd/yyyy" }}</span>
          </td>
          <td [ngClass]="report.count_images !== 0 ? 'text-primary' : 'text-muted'">
            <span>{{ report.count_images }}</span>
          </td>
          <td [ngClass]="report.count_files !== 0 ? 'text-primary' : 'text-muted'">
            <span>{{ report.count_files }}</span>
          </td>
          <td>
            <span>{{ report.summary }}</span>
          </td>
          <td>
            <a class="btn btn-link icon icon-news" [class.text-danger]="!report.read_at" [routerLink]="[report.id]"></a>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</ng-container>

<router-outlet></router-outlet>
