import { DomSanitizer } from "@angular/platform-browser"
import { Pipe } from "@angular/core"
import { PipeTransform } from "@angular/core"
import { SafeUrl } from "@angular/platform-browser"

@Pipe({ name: "trust" })
export class PipeTrust implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(value: string | null, kind = "url"): SafeUrl {
    if (value) {
      if (kind === "css") {
        return this.sanitizer.bypassSecurityTrustStyle(`url(${value})`)
      }
      if (kind === "resource-url") {
        return this.sanitizer.bypassSecurityTrustResourceUrl(value.toString())
      }
      if (kind === "url") {
        return this.sanitizer.bypassSecurityTrustUrl(value.toString())
      }
    }
    return ""
  }
}
