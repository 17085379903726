<div class="modal-body">
  <h3 class="text-center">Welcome</h3>
  <video class="d-b width-100" controls [autoplay]="autoplay" [muted]="muted">
    <source [src]="video" type="video/mp4" />
    Your browser does not support the video player.
  </video>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-default btn-wide" (click)="modal.hide()">Close</button>
  <button type="button" class="btn btn-primary btn-wide" (click)="onClick()">Got it</button>
</div>
