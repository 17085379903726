<div class="container m-t-lg">
  <div class="row">
    <div [ngSwitch]="tokenStatus" class="col-md-6 col-md-offset-3 text-center well well-z">
      <ng-container *ngSwitchCase="'invalid'">
        <i class="icon icon-emoji-sad"></i>
        <h3>Invalid Token</h3>
        <h5>If you need immediate assistance, please <a href="mailto:support@dentalemr.com">send</a> us a email</h5>
      </ng-container>
      <ng-container *ngSwitchCase="'pending-validation'">
        <div class="sk-chase">
          <div class="sk-chase-dot"></div>
          <div class="sk-chase-dot"></div>
          <div class="sk-chase-dot"></div>
          <div class="sk-chase-dot"></div>
          <div class="sk-chase-dot"></div>
          <div class="sk-chase-dot"></div>
        </div>
        <h3>Please wait</h3>
        <h5>Until we validate the token</h5>
      </ng-container>
    </div>
  </div>
</div>
