import { GuardGetPractice } from "app/guards"
import { GuardIsAuthenticated } from "app/guards"
import { LayoutMainComponent } from "app/layouts/main"
import { RouteLoginComponent } from "app/routes"
import { RoutePasswordResetComponent } from "app/routes"
import { RoutePasswordSetComponent } from "app/routes"
import { RouteReferralComponent } from "app/routes"
import { RouteReferralsComponent } from "app/routes"
import { RouteReportComponent } from "app/routes"
import { RouteReportsComponent } from "app/routes"
import { RouteTokenComponent } from "app/routes"
import { RouteWildcardComponent } from "app/routes"
import type { Routes } from "@angular/router"

export const routes: Routes = [
  {
    path: "",
    redirectTo: "login",
    pathMatch: "full",
  },
  {
    path: "login",
    component: RouteLoginComponent,
  },
  {
    path: "password-reset",
    component: RoutePasswordResetComponent,
  },
  {
    path: "password-set",
    component: RoutePasswordSetComponent,
  },
  {
    path: "referrals",
    component: LayoutMainComponent,
    canActivate: [GuardIsAuthenticated],
    children: [
      {
        path: "",
        redirectTo: "sent",
        pathMatch: "full",
      },
      {
        path: "sent",
        component: RouteReferralsComponent,
        children: [
          {
            path: ":referralId",
            component: RouteReferralComponent,
            children: [
              {
                path: "reports",
                component: RouteReportsComponent,
                children: [
                  {
                    path: ":reportId",
                    component: RouteReportComponent,
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: "token",
    children: [
      {
        path: "",
        redirectTo: "404",
        pathMatch: "full",
      },
      {
        path: ":token",
        component: RouteTokenComponent,
      },
    ],
  },
  {
    path: "~/:practiceId",
    canActivate: [GuardGetPractice],
    children: [
      {
        path: "login",
        component: RouteLoginComponent,
      },
    ],
  },
  {
    path: "**",
    component: RouteWildcardComponent,
  },
]
