import { ActivatedRouteSnapshot } from "@angular/router"
import { CanActivate } from "@angular/router"
import { Injectable } from "@angular/core"
import { Observable } from "rxjs"
import { ServicePractice } from "app/services/practice"
import { mapTo } from "rxjs/operators"

@Injectable({ providedIn: "root" })
export class GuardGetPractice implements CanActivate {
  constructor(private servicePractice: ServicePractice) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this.servicePractice.retrieve(route.params.practiceId).pipe(mapTo(true))
  }
}
