import { catchError, map } from "rxjs/operators"

import { BehaviorSubject } from "rxjs"
import { HttpClient } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { Observable } from "rxjs"
import { Picture } from "app/picture.class"
import { of } from "rxjs"
import { skip } from "rxjs/operators"
import { tap } from "rxjs/operators"

const api = "/api/v3/~referrals-external/practice"

@Injectable({ providedIn: "root" })
export class ServicePractice {
  readonly current: BehaviorSubject<Practice>

  readonly defaultPractice: Practice = {
    id: null,
    name: "DentalEMR",
    picture: new Picture(null),
  }

  constructor(private http: HttpClient) {
    this.current = new BehaviorSubject(this.defaultPractice)
    this.current.pipe(skip(1)).subscribe((_practice_) => {})
  }

  retrieve(id: number) {
    return this.http.get<PracticeResponse>(`${api}/${id}`).pipe(this.fix(), this.map(), this.tap())
  }

  private fix() {
    return catchError<PracticeResponse, Observable<PracticeResponse>>(() => of(this.defaultPractice))
  }

  private map() {
    return map<PracticeResponse, Practice>(
      ({
        picture, //
        ...props
      }) => ({
        ...props,
        picture: new Picture(picture),
      }),
    )
  }

  private tap() {
    return tap<Practice>((practice) => this.current.next(practice))
  }
}

export type Practice = {
  id: number | null
  name: string
  picture: Picture
}

type PracticeResponse = {
  id: number | null
  name: string
  picture: string | null | Picture
}
