import { ActivatedRoute } from "@angular/router"
import { Component } from "@angular/core"
import { OnInit } from "@angular/core"
import { Router } from "@angular/router"
import { ServiceToken } from "app/services/token"
import { ServiceUser } from "app/services/user"

@Component({ styleUrls: ["index.scss"], templateUrl: "index.html" })
export class RouteTokenComponent implements OnInit {
  tokenStatus: "invalid" | "pending-validation" = "pending-validation"
  resp: any = {}

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private serviceToken: ServiceToken,
    private serviceUser: ServiceUser,
  ) {}
  ngOnInit() {
    this.route.params.subscribe({
      next: ({ token }) => {
        this.serviceToken.unsign(token).subscribe({
          next: (resp) => {
            const { url, user } = resp
            const { id, email, name, has_password, practice, token } = user

            if (url === "set-password") {
              return this.router.navigate(["password-set", { id, email, name, practice, token }], {
                replaceUrl: true,
              })
            }

            if (has_password === false) {
              return this.router.navigate(["password-set", { id, email, name, practice, token }], {
                replaceUrl: true,
                queryParams: { next: url },
              })
            }

            if (id === this.serviceUser.current.getValue().id) {
              return this.router.navigate([url], {
                replaceUrl: true,
              })
            }

            return this.router.navigate(["~", practice, "login", { email }], {
              replaceUrl: true,
              queryParams: { next: url },
            })
          },
          error: (resp) => {
            this.tokenStatus = "invalid"
            throw resp
          },
        })
      },
    })
  }
}
