import { AbstractControl } from "@angular/forms"
import { BsModalRef } from "ngx-bootstrap/modal"
import { Component } from "@angular/core"
import { FormBuilder } from "@angular/forms"
import { PASSWORD_PATTERN } from "app/patterns"
import { ResponseStatus } from "app/enums/response-status"
import { ServiceUser } from "app/services/user"
import { ToastrService } from "ngx-toastr"
import { ValidatorFn } from "@angular/forms"
import { Validators } from "@angular/forms"

@Component({ styleUrls: ["../form.scss"], templateUrl: "index.html" })
export class ModalPasswordChangeComponent {
  formGroup = this.formBuilder.group(
    {
      password_cur: ["", [Validators.required]],
      password_new: ["", [Validators.required, Validators.pattern(PASSWORD_PATTERN)]],
      password_rep: [""],
      password_txt: [false],
    },
    {
      validators: formGroupValidator(),
    },
  )

  loading = false

  constructor(
    private formBuilder: FormBuilder,
    private serviceUser: ServiceUser,
    private toastr: ToastrService,
    public modal: BsModalRef,
  ) {}

  onSubmit() {
    if (this.formGroup.valid) {
      this.loading = true
      const data = (({ password_cur, password_new }) => ({ password_cur, password_new }))(this.formGroup.value)
      this.serviceUser.passwordChange(data).subscribe(
        () => {
          this.modal.hide()
          this.toastr.success("Done")
        },
        (resp) => {
          const {
            error: { errors = [], ...fields },
            status,
            statusText,
          } = resp
          // console.error(JSON.stringify({ status, statusText, errors, fields }, null, 2)) //-> only-for-testing
          if (status === ResponseStatus.BAD_REQUEST) {
            for (let field in fields) {
              const f = this.formGroup.get(field)
              if (f) {
                f.setErrors({ ...f.errors, server: fields[field] })
              }
            }
            this.formGroup.setErrors({ ...this.formGroup.errors, server: errors })
          } else {
            this.toastr.error("We will investigate the problem.", `${statusText}`)
            throw new Error(resp)
          }
        },
        () => {
          this.loading = false
        },
      )
    }
  }
}

export function formGroupValidator(): ValidatorFn {
  return (formGroup: AbstractControl) => {
    const pnew = formGroup.get("password_new")!
    const prep = formGroup.get("password_rep")!
    const ptxt = formGroup.get("password_txt")!
    if (ptxt.value) {
      prep.disable({ onlySelf: true })
    } else {
      prep.enable({ onlySelf: true })
    }
    if (!ptxt.value && pnew.value && pnew.value != prep.value) {
      prep.setErrors({ mismatch: true })
    } else {
      prep.setErrors(null)
    }
    return null
  }
}
