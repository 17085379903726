import { ActivatedRoute } from "@angular/router"
import { Component } from "@angular/core"
import { OnInit } from "@angular/core"
import type { ListResponse as Report } from "app/services/report"
import { ServiceReport } from "app/services/report"
import { ToastrService } from "ngx-toastr"

@Component({ styleUrls: ["index.scss"], templateUrl: "index.html" })
export class RouteReportsComponent implements OnInit {
  loading = 0

  filters = { referral: "" }

  reports: Report[] | null = null

  constructor(private serviceReport: ServiceReport, private toastr: ToastrService, public route: ActivatedRoute) {}

  ngOnInit() {
    this.route.parent?.params.subscribe({
      next: ({ referralId }) => {
        this.filters.referral = referralId
        this.list()
      },
    })
  }

  list() {
    this.loading++
    this.serviceReport.list(this.filters).subscribe({
      next: (resp) => {
        this.loading--
        this.reports = resp
      },
      error: (resp) => {
        this.loading--
        this.reports = null
        this.toastr.error("We will investigate the problem.", `${resp.statusText}`)
        throw new Error(resp)
      },
    })
  }

  trackById(_index: number, item: any) {
    return item.id
  }
}
