<div class="dashhead">
  <div class="dashhead-titles">
    <h6 class="dashhead-subtitle">
      <span [hidden]="!loading">loading...</span>
      &nbsp;
    </h6>
    <h3 class="dashhead-title">Referral Report</h3>
  </div>
  <div class="dashhead-toolbar">
    <a class="btn btn-primary-outline btn-wide" [routerLink]="['..']">Back</a>
  </div>
</div>

<div class="row m-t-md">
  <div class="col-sm-6 form-horizontal">
    <div class="form-group">
      <label class="col-sm-4 control-label">First Name</label>
      <div class="col-sm-8">
        <p class="form-control-static">
          <span *ngIf="report">{{ report.patient.first_name }}</span>
        </p>
      </div>
    </div>
    <div class="form-group">
      <label class="col-sm-4 control-label">Last Name</label>
      <div class="col-sm-8">
        <p class="form-control-static">
          <span *ngIf="report">{{ report.patient.last_name }}</span>
        </p>
      </div>
    </div>
    <div class="form-group">
      <label class="col-sm-4 control-label">Date of Birth</label>
      <div class="col-sm-8">
        <p class="form-control-static">
          <span *ngIf="report">{{ report.patient.birth_date | date:"MM/dd/yyyy" }}</span>
        </p>
      </div>
    </div>
    <div class="form-group">
      <label class="col-sm-4 control-label">Home Phone</label>
      <div class="col-sm-8">
        <p class="form-control-static">
          <span *ngIf="report">{{ report.patient.home_phone | fmt:"phone-number" }}</span>
        </p>
      </div>
    </div>
    <div class="form-group">
      <label class="col-sm-4 control-label">Mobile Phone</label>
      <div class="col-sm-8">
        <p class="form-control-static">
          <span *ngIf="report">{{ report.patient.mobile_phone | fmt:"phone-number" }}</span>
        </p>
      </div>
    </div>
    <div class="form-group">
      <label class="col-sm-4 control-label">Published</label>
      <div class="col-sm-8">
        <p class="form-control-static">{{ report?.sent_at | date:"MM/dd/yyyy" }}</p>
      </div>
    </div>
  </div>
  <div class="col-sm-6">
    <div class="btn-group pull-right">
      <button type="button" class="btn btn-default" (click)="onReportClick('pdf-view')">
        <i class="icon icon-eye"></i>
      </button>
      <button type="button" class="btn btn-default" (click)="onReportClick('pdf-download')">
        <i class="icon icon-download"></i>
      </button>
    </div>
  </div>
</div>

<div class="row m-t" *ngIf="report?.summary as summary">
  <div class="col-sm-12">
    <p>Regarding {{ summary }}</p>
  </div>
</div>

<div class="row m-t" *ngIf="report?.description as description">
  <div class="col-sm-12">
    <p class="preserve-wrap">{{ description }}</p>
  </div>
</div>

<ng-container *ngIf="report?.images as images">
  <ng-container *ngIf="images.length > 0">
    <h2 class="m-t text-center">Resulting Images</h2>
    <div class="row">
      <div class="col-xs-12 col-sm-4 col-md-3" *ngFor="let image of images; trackBy:trackById">
        <div class="thumbnail">
          <div class="image">
            <div class="btn-group">
              <button type="button" class="btn" (click)="onImagesClick('view', image)">
                <i class="icon icon-eye"></i>
              </button>
              <button type="button" class="btn" (click)="onImagesClick('download', image)">
                <i class="icon icon-download"></i>
              </button>
            </div>
            <div class="sk-wave" *ngIf="image.$loading">
              <div class="sk-wave-rect"></div>
              <div class="sk-wave-rect"></div>
              <div class="sk-wave-rect"></div>
              <div class="sk-wave-rect"></div>
              <div class="sk-wave-rect"></div>
            </div>
            <div class="preview" [style.background-image]="image.picture.url | async | trust:'css'"></div>
          </div>
          <h5>{{ image.name }}</h5>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>

<ng-container *ngIf="report?.files as files">
  <ng-container *ngIf="files.length > 0">
    <h2 class="m-t text-center">Additional Files</h2>
    <div class="row">
      <div class="col-xs-12 col-sm-4 col-md-3" *ngFor="let file of files; trackBy:trackById">
        <div class="thumbnail">
          <div class="image">
            <div class="btn-group">
              <button type="button" class="btn" (click)="onFilesClick('download', file)">
                <i class="icon icon-download"></i>
              </button>
            </div>
            <div class="sk-wave" *ngIf="file.$loading">
              <div class="sk-wave-rect"></div>
              <div class="sk-wave-rect"></div>
              <div class="sk-wave-rect"></div>
              <div class="sk-wave-rect"></div>
              <div class="sk-wave-rect"></div>
            </div>
            <div class="preview icomoon" [ngClass]="file.metadata.content_type | fmt:'content-type'"></div>
            <h6>{{ file.metadata.size | fmt:"file-size" }}</h6>
          </div>
          <h5>{{ file.name }}</h5>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>

<ng-container *ngIf="report?.close_message as close_message">
  <p class="m-t-md preserve-wrap">{{ close_message }}</p>
</ng-container>

<ng-container *ngIf="report?.signing_provider as signing_provider">
  <div class="m-t-lg" style="max-width: 300px">
    <img class="img-thumbnail" [src]="signing_provider.signature" />
    <p class="text-center">{{ signing_provider.first_name }} {{ signing_provider.last_name }}</p>
  </div>
</ng-container>

<div class="row">
  <div class="col-sm-12">
    <hr class="m-t-md" />
    <div class="pull-right">
      <a class="btn btn-wide btn-primary-outline" routerLink="..">Back</a>
    </div>
  </div>
</div>
