<app-header></app-header>

<div class="container container-main p-y">
  <router-outlet></router-outlet>
</div>

<h6 class="text-center m-b-0">
  Powered by
  <a href="//www.dentalemr.com" target="_blank">Dental EMR</a>
</h6>
