import { APP_INITIALIZER } from "@angular/core"
import { AppComponent } from "app/app.component"
import { BrowserAnimationsModule } from "@angular/platform-browser/animations"
import { BrowserModule } from "@angular/platform-browser"
import { BsDropdownModule } from "ngx-bootstrap/dropdown"
import { CollapseModule } from "ngx-bootstrap/collapse"
import { DatePipe } from "@angular/common"
import { FormsModule } from "@angular/forms"
import { GooglePlaceModule } from "ngx-google-places-autocomplete"
import { HeaderComponent } from "app/components/header"
import { HttpClientModule } from "@angular/common/http"
import { HttpClientXsrfModule } from "@angular/common/http"
import { IMaskModule } from "angular-imask"
import { LayoutMainComponent } from "app/layouts/main"
import { MODAL_CONFIG_DEFAULT_OVERRIDE } from "ngx-bootstrap/modal"
import { ModalFeedbackComponent } from "app/modals/feedback"
import { ModalLicenseComponent } from "app/modals/license"
import { ModalModule } from "ngx-bootstrap/modal"
import { ModalPasswordChangeComponent } from "app/modals/password-change"
import { ModalWelcomeComponent } from "app/modals/welcome"
import { NgModule } from "@angular/core"
import { NgxSelectModule } from "ngx-select-ex"
import { PipeDebug } from "app/pipes/debug"
import { PipeFmt } from "app/pipes/fmt"
import { PipeGet } from "app/pipes/get"
import { PipeTruncate } from "app/pipes/truncate"
import { PipeTrust } from "app/pipes/trust"
import { ReactiveFormsModule } from "@angular/forms"
import { RouteLoginComponent } from "app/routes"
import { RoutePasswordResetComponent } from "app/routes"
import { RoutePasswordSetComponent } from "app/routes"
import { RouteReferralComponent } from "app/routes"
import { RouteReferralsComponent } from "app/routes"
import { RouteReportComponent } from "app/routes"
import { RouteReportsComponent } from "app/routes"
import { RouteTokenComponent } from "app/routes"
import { RouteWildcardComponent } from "app/routes"
import { RouterModule } from "@angular/router"
import { ServiceUser } from "app/services/user"
import { TabsModule } from "ngx-bootstrap/tabs"
import { ToastrModule } from "ngx-toastr"
import { TooltipConfig } from "ngx-bootstrap/tooltip"
import { TooltipModule } from "ngx-bootstrap/tooltip"
import { UserIdleModule } from "angular-user-idle"
import { ngfModule } from "angular-file"
import { routes } from "app/app.routes"
import { AlertModule, AlertConfig } from "ngx-bootstrap/alert"

@NgModule({
  bootstrap: [AppComponent],
  declarations: [
    AppComponent,
    HeaderComponent,
    LayoutMainComponent,
    ModalFeedbackComponent,
    ModalLicenseComponent,
    ModalPasswordChangeComponent,
    ModalWelcomeComponent,
    PipeDebug,
    PipeFmt,
    PipeGet,
    PipeTruncate,
    PipeTrust,
    RouteLoginComponent,
    RoutePasswordResetComponent,
    RoutePasswordSetComponent,
    RouteReferralComponent,
    RouteReferralsComponent,
    RouteReportComponent,
    RouteReportsComponent,
    RouteTokenComponent,
    RouteWildcardComponent,
  ],
  imports: [
    AlertModule,
    BrowserAnimationsModule,
    BrowserModule,
    BsDropdownModule.forRoot(),
    CollapseModule.forRoot(),
    FormsModule,
    GooglePlaceModule,
    HttpClientModule,
    HttpClientXsrfModule.withOptions({ cookieName: "csrftoken", headerName: "X-CSRFToken" }),
    IMaskModule,
    ModalModule.forRoot(),
    NgxSelectModule.forRoot({
      autoSelectSingleOption: true,
      keepSelectedItems: false,
      optionTextField: "name",
    }),
    ReactiveFormsModule,
    RouterModule.forRoot(routes, { enableTracing: false }),
    TabsModule.forRoot(),
    ToastrModule.forRoot({ disableTimeOut: "extendedTimeOut", enableHtml: true, newestOnTop: false }),
    TooltipModule.forRoot(),
    UserIdleModule.forRoot({ idle: 10 * 60 * 1048576, timeout: 4 * 60, ping: 5 * 60 }),
    ngfModule,
  ],
  providers: [
    AlertConfig,
    DatePipe,
    {
      provide: APP_INITIALIZER,
      useFactory: (serviceUser: ServiceUser) => () => serviceUser.authenticated(),
      deps: [ServiceUser],
      multi: true,
    },
    {
      provide: MODAL_CONFIG_DEFAULT_OVERRIDE,
      useValue: {
        backdrop: false,
        ignoreBackdropClick: true,
      },
    },
    {
      provide: TooltipConfig,
      useFactory: () => Object.assign(new TooltipConfig(), { container: "body" }),
    },
  ],
})
export class AppModule {}
