import { Component } from "@angular/core"
import { FormBuilder } from "@angular/forms"
import { ResponseStatus } from "app/enums/response-status"
import { Router } from "@angular/router"
import { ServicePractice } from "app/services/practice"
import { ServiceUser } from "app/services/user"
import { ToastrService } from "ngx-toastr"
import { Validators } from "@angular/forms"

@Component({ styleUrls: ["../form.scss"], templateUrl: "index.html" })
export class RoutePasswordResetComponent {
  formGroup = this.formBuilder.group({
    email: ["", [Validators.email, Validators.required]],
  })

  loading = false

  practice = this.servicePractice.defaultPractice

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private servicePractice: ServicePractice,
    private serviceUser: ServiceUser,
    private toastr: ToastrService,
  ) {}

  onSubmit() {
    if (this.formGroup.valid) {
      this.loading = true
      this.serviceUser.passwordReset(this.formGroup.value).subscribe(
        () => {
          this.router.navigate(["/login"]).then(() => {
            this.toastr.success("Check your email inbox for intructions", "", {
              disableTimeOut: true,
            })
          })
        },
        (resp) => {
          const {
            error: { errors = [], ...fields },
            status,
            statusText,
          } = resp
          // console.error(JSON.stringify({ status, statusText, errors, fields }, null, 2)) //-> only-for-testing
          if (status === ResponseStatus.BAD_REQUEST) {
            for (let field in fields) {
              const f = this.formGroup.get(field)
              if (f) {
                f.setErrors({ ...f.errors, server: fields[field] })
              }
            }
            this.formGroup.setErrors({ ...this.formGroup.errors, server: errors })
          } else {
            this.toastr.error("We will investigate the problem.", `${statusText}`)
            throw new Error(resp)
          }
        },
        () => {
          this.loading = false
        },
      )
    }
  }
}
