import { HttpClient } from "@angular/common/http"
import { HttpParams } from "@angular/common/http"
import { HttpParamsOptions } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { Picture } from "app/picture.class"
import { map } from "rxjs/operators"

const api = "/api/v3/~referrals-external/report"

@Injectable({ providedIn: "root" })
export class ServiceReport {
  constructor(private http: HttpClient) {}

  list(params: HttpParamsOptions["fromObject"] = {}) {
    type ApiResponse = ListResponse
    return this.http.get<ApiResponse[]>(`${api}`, { params: new HttpParams({ fromObject: params }) })
  }

  retrieve(id: number | string) {
    type ApiResponse = Omit<RetrieveResponse, "images"> & {
      images: {
        id: number
        name: string
        url: string
      }[]
    }
    return this.http.get<ApiResponse>(`${api}/${id}`).pipe(
      map<ApiResponse, RetrieveResponse>(
        ({
          images = [], //
          ...props
        }) => ({
          ...props,
          images: images.map(
            ({
              url, //
              ...props
            }) => ({
              ...props,
              picture: new Picture(url, this.http, "/assets/no-thumb.jpg"),
            }),
          ),
        }),
      ),
    )
  }
}

export type ListResponse = {
  id: number
  sent_at: string | null
  count_images: number
  count_files: number
  summary: string
  read_at: string
}

export type RetrieveResponse = {
  id: number
  close_message: string
  description: string
  files: {
    id: number
    metadata: {
      content_type: string
      size: number
    }
    name: string
    $loading?: boolean
  }[]
  images: {
    id: number
    name: string
    picture: Picture
    $loading?: boolean
  }[]
  patient: {
    first_name: string
    last_name: string
    birth_date: string
    home_phone: string
    mobile_phone: string
  }
  practice: {
    name: string
    picture: string
    location: {
      street1: string
      street2: string
      city: string
      state: string
      zip_code: string
    }
    phone: string
  }
  sent_at: string | null
  signing_provider: {
    id: number | null
    first_name: string
    last_name: string
    signature: string
  }
  summary: string
}

export type PdfResponse = {
  url: string
}
