import { AbstractControl } from "@angular/forms"
import { FormControl } from "@angular/forms"
import { Pipe } from "@angular/core"
import { PipeTransform } from "@angular/core"

export interface FormControlEx extends FormControl {
  readonly: boolean
}

@Pipe({ name: "get" })
export class PipeGet implements PipeTransform {
  transform(value: any, key: string): any {
    if (value instanceof AbstractControl) {
      const f = value.get(key)
      if (f) {
        return f as FormControlEx
      }
    }
    return null
  }
}
