<div class="container m-t-lg">
  <div class="row">
    <div class="col-md-6 col-md-offset-3 well well-z m-b-0">
      <div class="sk-chase" *ngIf="loading">
        <div class="sk-chase-dot"></div>
        <div class="sk-chase-dot"></div>
        <div class="sk-chase-dot"></div>
        <div class="sk-chase-dot"></div>
        <div class="sk-chase-dot"></div>
        <div class="sk-chase-dot"></div>
      </div>
      <img class="img-thumbnail" [attr.src]="practice.picture.url | async | trust" />
      <h3 class="text-center m-b">{{ practice.name }} Referrals Portal</h3>
      <form [formGroup]="formGroup" (ngSubmit)="onSubmit()" *ngIf="formGroup | get:'passwordVisible' as txt">
        <div class="form-group has-feedback" *ngIf="formGroup | get:'email' as f" [ngClass]="{'has-error': f.invalid}">
          <label class="control-label">Email</label>
          <input class="form-control" [formControl]="f" type="email" [readonly]="f.readonly"/>
          <span class="icon icon-warning form-control-feedback" *ngIf="f.invalid" [tooltip]="errors">
            <ng-template #errors>
              <div *ngIf="f.hasError('email')">Invalid.</div>
              <div *ngIf="f.hasError('invalid')">Invalid.</div>
              <div *ngIf="f.hasError('required')">Required.</div>
              <div *ngFor="let e of f.getError('server')">{{ e }}</div>
            </ng-template>
          </span>
        </div>
        <div class="form-group has-feedback" *ngIf="formGroup | get:'password' as f" [ngClass]="{'has-error': f.invalid}">
          <label class="control-label">Password</label>
          <input class="form-control" [formControl]="f" [type]="txt.value ? 'text' : 'password'" />
          <span class="icon icon-warning form-control-feedback" *ngIf="f.invalid" [tooltip]="errors">
            <ng-template #errors>
              <div *ngIf="f.hasError('required')">Required</div>
            </ng-template>
          </span>
        </div>
        <div class="form-group" *ngIf="txt as f">
          <div class="checkbox custom-control custom-checkbox">
            <label>
              <input type="checkbox" [formControl]="f" />
              <span class="custom-control-indicator"></span>
              Show Password
            </label>
          </div>
        </div>
        <ng-container *ngIf="formGroup | get:'practice' as f">
          <div class="form-group" [ngClass]="{'has-error': f.invalid}" *ngIf="practices.length > 0">
            <div class="list-group">
              <div class="list-group-item" [ngClass]="{'list-group-item-danger': f.invalid}">You need to select one of your following Practices</div>
              <ng-container *ngFor="let c of practices">
                <ng-container [ngSwitch]="c.password_valid && c.user_active">
                  <ng-container *ngSwitchCase="true">
                    <button type="button" class="list-group-item">
                      <div class="radio-inline custom-control custom-radio">
                        <label>
                          <input type="radio" [formControl]="f" [value]="c.id" (click)="onClickPractice(f, c)" />
                          <span class="custom-control-indicator"></span>
                          <span>{{ c.name }}</span>
                        </label>
                      </div>
                    </button>
                  </ng-container>
                  <ng-container *ngSwitchCase="false">
                    <div class="list-group-item">
                      <i class="icon icon-key pull-right" *ngIf="!c.password_valid" tooltip="The provided password is invalid for this practice."></i>
                      <i class="icon icon-remove-user pull-right" *ngIf="!c.user_active" tooltip="User has been deactivated."></i>
                      <div class="radio-inline custom-control custom-radio">
                        <label>
                          <input type="radio" disabled />
                          <span class="custom-control-indicator"></span>
                          <span class="text-muted">{{ c.name }}</span>
                        </label>
                      </div>
                    </div>
                  </ng-container>
                </ng-container>
              </ng-container>
            </div>
          </div>
        </ng-container>
        <button type="submit" class="btn btn-primary btn-lg btn-block">Sign in</button>
        <h6 class="text-center m-b-0">
          Powered by
          <a href="//www.dentalemr.com" target="_blank">Dental EMR</a>
        </h6>
      </form>
      <div class="alert alert-danger" *ngIf="formGroup.hasError('server') && formGroup as f">
        <div *ngFor="let e of f.getError('server')">{{ e }}</div>
      </div>
    </div>
    <div class="col-md-6 col-md-offset-3">
      <h5 class="text-center">
        Forgot your password? Click
        <a [routerLink]="['/', 'password-reset']">here</a>
        to reset.
      </h5>
      <h5 class="text-center">Don't have an account? Contact the practice to obtain your credentials to refer cases and receive reports.</h5>
    </div>
  </div>
</div>
