import { AbstractControl } from "@angular/forms"
import { ActivatedRoute } from "@angular/router"
import { Component } from "@angular/core"
import type { ErrorPracticeRequired } from "app/services/user"
import { FormBuilder } from "@angular/forms"
import { FormControlEx } from "app/pipes/get"
import { OnInit } from "@angular/core"
import { ResponseStatus } from "app/enums/response-status"
import { Router } from "@angular/router"
import { ServiceModal } from "app/services/modal"
import { ServicePractice } from "app/services/practice"
import { ServiceUser } from "app/services/user"
import { ToastrService } from "ngx-toastr"
import { ValidatorFn } from "@angular/forms"
import { Validators } from "@angular/forms"

@Component({ styleUrls: ["../form.scss"], templateUrl: "index.html" })
export class RouteLoginComponent implements OnInit {
  formGroup = this.formBuilder.group({
    email: ["", [Validators.email, Validators.required]],
    password: ["", Validators.required],
    passwordVisible: [false],
    practice: [null],
  })

  loading = false

  practice = this.servicePractice.current.getValue()

  practices: ErrorPracticeRequired[] = []

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private serviceModal: ServiceModal,
    private servicePractice: ServicePractice,
    private serviceUser: ServiceUser,
    private toastr: ToastrService,
  ) {}

  ngOnInit() {
    this.route.params.subscribe(({ email: value }) => {
      if (value) {
        const f = this.formGroup.get("email") as FormControlEx
        if (f) {
          f.readonly = true
          f.setValue(value)
        }
      }
    })
    this.route.params.subscribe(({ practiceId: value }) => {
      if (value) {
        value = Number(value)
        if (value !== this.practice.id) {
          this.router.navigate(["/login"]).then(() => {
            this.toastr.error(`Practice (${value}) specified in the url is not valid.`)
          })
        } else {
          const f = this.formGroup.get("practice")
          if (f) {
            f.setValue(value)
          }
        }
      }
    })
  }

  onSubmit() {
    if (this.formGroup.valid) {
      this.loading = true
      this.serviceUser.login(this.formGroup.value).subscribe(
        (user) => {
          this.loading = false
          this.toastr.clear()
          this.router.navigate([this.route.snapshot.queryParams.next || "/referrals/sent"]).then(() => {
            if (user.show_welcome) {
              this.serviceModal.welcome(true)
            }
          })
        },
        (resp) => {
          this.loading = false
          const {
            error: { errors = [], practice = {}, ...fields },
            status,
            statusText,
          } = resp
          // console.error(JSON.stringify({ status, statusText, errors, practice, fields }, null, 2)) //-> for-testing
          if (status === ResponseStatus.BAD_REQUEST) {
            if (practice.required) {
              setTimeout(() => {
                this.practices = practice.required
                const f = this.formGroup.get("practice")!
                f.setValidators(formGroupPracticeValidator(this.practices.map(({ id }) => id)))
                f.updateValueAndValidity()
                if (!practice.required.find((userPractice: ErrorPracticeRequired) => userPractice.is_available)) {
                  errors.push("There is no Practice available for the provided credentials")
                }
              }, 0)
            }
            for (let field in fields) {
              const f = this.formGroup.get(field)
              if (f) {
                f.setErrors({ ...f.errors, server: fields[field] })
              }
            }
            this.formGroup.setErrors({ ...this.formGroup.errors, server: errors })
          } else {
            this.toastr.error("We will investigate the problem.", `${statusText}`)
            throw new Error(resp)
          }
        },
      )
    }
  }

  onClickPractice(f: AbstractControl, practice: ErrorPracticeRequired) {
    if (practice.password_valid && practice.user_active) {
      if (f.value == practice.id) {
        f.setValue(null)
        this.practice = this.servicePractice.defaultPractice
      } else {
        this.practice = practice
      }
    }
  }
}

export function formGroupPracticeValidator(choices: any[]): ValidatorFn {
  return (control) => {
    if (choices?.length > 0) {
      if (!choices.includes(control.value)) {
        return { choice: { value: control.value, valid: choices } }
      }
    }
    return null
  }
}
