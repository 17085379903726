import * as dayjs from "dayjs"

import { ActivatedRoute } from "@angular/router"
import { Component } from "@angular/core"
import { FormBuilder } from "@angular/forms"
import { OnInit } from "@angular/core"
import type { ListResponse as Referral } from "app/services/referral"
import { ServiceReferral } from "app/services/referral"
import { ToastrService } from "ngx-toastr"

@Component({ styleUrls: ["index.scss"], templateUrl: "index.html" })
export class RouteReferralsComponent implements OnInit {
  formGroup = this.formBuilder.group({
    limitto: this.formBuilder.group({
      period: [""],
      uncompleted: [false],
    }),
    orderby: this.formBuilder.group({
      patient: [false],
    }),
  })

  loading = 0

  referrals: Referral[] | null = []

  constructor(
    private formBuilder: FormBuilder,
    private serviceReferral: ServiceReferral,
    private toastr: ToastrService,
    public route: ActivatedRoute,
  ) {}

  ngOnInit() {
    this.list()
    this.formGroup.valueChanges.subscribe(() => this.list())
  }

  list() {
    this.loading++
    this.serviceReferral.list(this.filters).subscribe({
      next: (resp) => {
        this.loading--
        this.referrals = resp
      },
      error: (resp) => {
        this.loading--
        this.referrals = null
        this.toastr.error("We will investigate the problem.", `${resp.statusText}`)
        throw new Error(resp)
      },
    })
  }

  trackById(_index: number, item: any) {
    return item.id
  }

  private get filters() {
    const {
      limitto: { period, uncompleted },
      orderby: { patient },
    } = this.formGroup.value
    const resp: { [key: string]: boolean | string | ReadonlyArray<string> } = {}
    if (period) {
      resp["date-range"] = [dayjs().startOf(period).format("YYYY-MM-DD"), dayjs().endOf(period).format("YYYY-MM-DD")]
    }
    if (uncompleted) {
      resp["is-completed"] = false
    }
    if (patient) {
      resp["order-by"] = "patient"
    }
    return resp
  }
}
