import { BsModalService } from "ngx-bootstrap/modal"
import { Injectable } from "@angular/core"
import { ModalFeedbackComponent } from "app/modals/feedback"
import { ModalLicenseComponent } from "app/modals/license"
import { ModalOptions } from "ngx-bootstrap/modal"
import { ModalPasswordChangeComponent } from "app/modals/password-change"
import { ModalWelcomeComponent } from "app/modals/welcome"

@Injectable({
  providedIn: "root",
})
export class ServiceModal {
  constructor(private modal: BsModalService) {}

  feedback() {
    return this.show(ModalFeedbackComponent)
  }

  license() {
    return this.show(ModalLicenseComponent, { class: "modal-lg" })
  }

  passwordChange() {
    return this.show(ModalPasswordChangeComponent)
  }

  welcome(muted = false) {
    return this.show(ModalWelcomeComponent, { class: "modal-lg", initialState: { muted } })
  }

  private show<T>(component: { new (...args: any[]): T }, config?: ModalOptions<T>) {
    return this.modal.show<T>(component, config)
  }
}
