<ng-container *ngIf="route.children.length === 0">
  <div class="dashhead">
    <div class="dashhead-titles">
      <h6 class="dashhead-subtitle">
        <span [hidden]="!loading">loading...</span>
        &nbsp;
      </h6>
      <h3 class="dashhead-title">
        Referral
        <small>
          <span *ngIf="isBeignCreated">to submit</span>
          <span *ngIf="isBeignUpdated">submitted</span>
        </small>
      </h3>
    </div>
    <div class="dashhead-toolbar">
      <a class="btn btn-wide btn-default" [routerLink]="['..']">Back</a>
      <button type="button" class="btn btn-wide btn-primary m-l" (click)="onSubmit()" *ngIf="formGroup.enabled">Save</button>
    </div>
  </div>
  <ul class="nav nav-bordered m-y">
    <li routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
      <a [routerLink]="['.']" class="p-x-md">
        <h4 class="m-y-0">General</h4>
      </a>
    </li>
    <li routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}" *ngIf="isBeignUpdated">
      <a [routerLink]="['reports']" class="p-x-md">
        <h4 class="m-y-0">Reports</h4>
      </a>
    </li>
  </ul>
  <fieldset class="m-t" *ngIf="isBeignCreated || (isBeignUpdated && formGroup.get('id')?.value)">
    <div class="row">
      <div class="col-sm-6 form-horizontal form-spaced">
        <div class="form-group has-feedback" *ngIf="formGroup.get('ext_practice') as f" [ngClass]="{'has-error': f.invalid}">
          <label class="col-sm-5 control-label">On behalf of Practice</label>
          <div class="col-sm-7">
            <!-- prettier-ignore -->
            <ngx-select [formControl]="$any(f)" [items]="refP7sItems"
              (selectionChanges)="onSelectionChangedRefP7sItems($event)">
            </ngx-select>
            <ng-container *ngIf="f.invalid">
              <span class="icon icon-warning form-control-feedback" [tooltip]="errors">
                <ng-template #errors>
                  <div *ngIf="f.hasError('required')">Required.</div>
                  <div *ngFor="let e of f.getError('server')">{{ e }}</div>
                </ng-template>
              </span>
            </ng-container>
          </div>
        </div>
        <div class="form-group has-feedback" *ngIf="formGroup.get('ext_user') as f" [ngClass]="{'has-error': f.invalid}">
          <label class="col-sm-5 control-label">Submitting User</label>
          <div class="col-sm-7">
            <ngx-select [formControl]="$any(f)" [items]="refU3sItems"></ngx-select>
            <ng-container *ngIf="f.invalid">
              <span class="icon icon-warning form-control-feedback" [tooltip]="errors">
                <ng-template #errors>
                  <div *ngIf="f.hasError('required')">Required.</div>
                  <div *ngFor="let e of f.getError('server')">{{ e }}</div>
                </ng-template>
              </span>
            </ng-container>
          </div>
        </div>
      </div>
      <alert *ngIf="showDialog" type="danger" style="position: fixed; top: 7%; right: 0; transition: all 0.5s ease-in-out">
        <strong>Warning:</strong> Changing Number of Referral from Today.
      </alert>
      <div class="col-sm-6 form-horizontal form-spaced">
        <div class="form-group has-feedback" *ngIf="formGroup.get('date') as f" [ngClass]="{'has-error': f.invalid}">
          <label class="col-sm-5 control-label">Referral Date</label>
          <div class="col-sm-7">
            <input type="date" class="form-control" [formControl]="$any(f)" (change)="onDateChange($event)" />
            <ng-container *ngIf="f.invalid">
              <span class="icon icon-warning form-control-feedback" [tooltip]="errors">
                <ng-template #errors>
                  <div *ngIf="f.hasError('required')">Required.</div>
                  <div *ngFor="let e of f.getError('server')">{{ e }}</div>
                </ng-template>
              </span>
            </ng-container>
          </div>
        </div>
        <div class="form-group has-feedback" *ngIf="formGroup.get('is_completed') as f" [ngClass]="{'has-error': f.invalid}">
          <label class="col-sm-5 control-label">Status</label>
          <div class="col-sm-7">
            <div class="form-control-static">{{ f.value ? "Completed" : "Not Completed" }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="formGroup.get('ext_patient') as g">
      <div class="hr-divider hr-divider-lg font-variant-normal m-y-md">
        <h3 class="hr-divider-content hr-divider-heading">Patient Information</h3>
      </div>
      <div class="col-sm-6 form-horizontal form-spaced">
        <div class="form-group has-feedback" [ngClass]="{'has-error': f.invalid}" *ngIf="g.get('first_name') as f">
          <label class="col-sm-5 control-label">First Name</label>
          <div class="col-sm-7">
            <input type="text" class="form-control" [formControl]="$any(f)" />
            <ng-container *ngIf="f.invalid">
              <span class="icon icon-warning form-control-feedback" [tooltip]="errors">
                <ng-template #errors>
                  <div *ngIf="f.getError('minlength') as e">Must be at least {{ e.requiredLength }} characters long.</div>
                  <div *ngIf="f.hasError('required')">Required.</div>
                  <div *ngFor="let e of f.getError('server')">{{ e }}</div>
                </ng-template>
              </span>
            </ng-container>
          </div>
        </div>
        <div class="form-group has-feedback" [ngClass]="{'has-error': f.invalid}" *ngIf="g.get('last_name') as f">
          <label class="col-sm-5 control-label">Last Name</label>
          <div class="col-sm-7">
            <input type="text" class="form-control" [formControl]="$any(f)" />
            <ng-container *ngIf="f.invalid">
              <span class="icon icon-warning form-control-feedback" [tooltip]="errors">
                <ng-template #errors>
                  <div *ngIf="f.getError('minlength') as e">Must be at least {{ e.requiredLength }} characters long.</div>
                  <div *ngIf="f.hasError('required')">Required.</div>
                  <div *ngFor="let e of f.getError('server')">{{ e }}</div>
                </ng-template>
              </span>
            </ng-container>
          </div>
        </div>
        <div class="form-group has-feedback" [ngClass]="{'has-error': f.invalid}" *ngIf="g.get('home_phone') as f">
          <label class="col-sm-5 control-label">Home Phone</label>
          <div class="col-sm-7">
            <input type="tel" class="form-control" [formControl]="$any(f)" [imask]="{mask: '000-000-0000', lazy: false}" [unmask]="true" />
            <ng-container *ngIf="f.invalid">
              <span class="icon icon-warning form-control-feedback" [tooltip]="errors">
                <ng-template #errors>
                  <div *ngIf="f.hasError('pattern')">Must be a valid phone number.</div>
                  <div *ngFor="let e of f.getError('server')">{{ e }}</div>
                </ng-template>
              </span>
            </ng-container>
          </div>
        </div>
        <div class="form-group has-feedback" [ngClass]="{'has-error': f.invalid}" *ngIf="g.get('mobile_phone') as f">
          <label class="col-sm-5 control-label">Mobile Phone</label>
          <div class="col-sm-7">
            <input type="tel" class="form-control" [formControl]="$any(f)" [imask]="{mask: '000-000-0000', lazy: false}" [unmask]="true" />
            <ng-container *ngIf="f.invalid">
              <span class="icon icon-warning form-control-feedback" [tooltip]="errors">
                <ng-template #errors>
                  <div *ngIf="f.hasError('pattern')">Must be a valid phone number.</div>
                  <div *ngFor="let e of f.getError('server')">{{ e }}</div>
                </ng-template>
              </span>
            </ng-container>
          </div>
        </div>
        <div class="form-group has-feedback" [ngClass]="{'has-error': f.invalid}" *ngIf="g.get('email') as f">
          <label class="col-sm-5 control-label">Email</label>
          <div class="col-sm-7">
            <input type="email" class="form-control" [formControl]="$any(f)" />
            <ng-container *ngIf="f.invalid">
              <span class="icon icon-warning form-control-feedback" [tooltip]="errors">
                <ng-template #errors>
                  <div *ngIf="f.hasError('email')">Must be a valid email address.</div>
                  <div *ngFor="let e of f.getError('server')">{{ e }}</div>
                </ng-template>
              </span>
            </ng-container>
          </div>
        </div>
        <div class="form-group has-feedback" *ngIf="g.get('birth_date') as f" [ngClass]="{'has-error': f.invalid}">
          <label class="col-sm-5 control-label">Birth Date</label>
          <div class="col-sm-7">
            <input type="date" class="form-control" [formControl]="$any(f)" />
            <ng-container *ngIf="f.invalid">
              <span class="icon icon-warning form-control-feedback" [tooltip]="errors">
                <ng-template #errors>
                  <div *ngIf="f.hasError('required')">Required.</div>
                  <div *ngFor="let e of f.getError('server')">{{ e }}</div>
                </ng-template>
              </span>
            </ng-container>
          </div>
        </div>
      </div>
      <div class="col-sm-6 form-horizontal form-spaced">
        <div class="form-group has-feedback">
          <label class="col-sm-5 control-label"></label>
          <div class="col-sm-7">
            <!-- prettier-ignore -->
            <input type="text" class="form-control" ngx-google-places-autocomplete *ngIf="formGroup.enabled"
              (onAddressChange)="onAddressChanged($event)"
              [options]="$any({componentRestrictions: {country: 'US'}, fields: ['address_components']})" />
            <p class="form-control-static" *ngIf="formGroup.disabled"></p>
          </div>
        </div>
        <div class="form-group has-feedback" [ngClass]="{'has-error': f.invalid}" *ngIf="g.get('patient_address_street_1') as f">
          <label class="col-sm-5 control-label">Address Line 1</label>
          <div class="col-sm-7">
            <input type="text" class="form-control" [formControl]="$any(f)" />
          </div>
        </div>
        <div class="form-group has-feedback" [ngClass]="{'has-error': f.invalid}" *ngIf="g.get('patient_address_street_2') as f">
          <label class="col-sm-5 control-label">Address Line 2</label>
          <div class="col-sm-7">
            <input type="text" class="form-control" [formControl]="$any(f)" />
          </div>
        </div>
        <div class="form-group has-feedback" [ngClass]="{'has-error': f.invalid}" *ngIf="g.get('patient_address_city') as f">
          <label class="col-sm-5 control-label">City</label>
          <div class="col-sm-7">
            <input type="text" class="form-control" [formControl]="$any(f)" />
          </div>
        </div>
        <div class="form-group has-feedback" [ngClass]="{'has-error': f.invalid}" *ngIf="g.get('patient_address_state') as f">
          <label class="col-sm-5 control-label">State</label>
          <div class="col-sm-7">
            <input type="text" class="form-control" [formControl]="$any(f)" [imask]="{mask: '**', lazy: false}" [unmask]="true" />
            <ng-container *ngIf="f.invalid">
              <span class="icon icon-warning form-control-feedback" [tooltip]="errors">
                <ng-template #errors>
                  <div *ngIf="f.hasError('pattern')">Require 2 uppercase letters.</div>
                  <div *ngFor="let e of f.getError('server')">{{ e }}</div>
                </ng-template>
              </span>
            </ng-container>
          </div>
        </div>
        <div class="form-group has-feedback" [ngClass]="{'has-error': f.invalid}" *ngIf="g.get('patient_address_zip_code') as f">
          <label class="col-sm-5 control-label">Postal Code</label>
          <div class="col-sm-7">
            <input type="text" class="form-control" [formControl]="$any(f)" [imask]="{mask: '00000', lazy: false}" [unmask]="true" />
            <ng-container *ngIf="f.invalid">
              <span class="icon icon-warning form-control-feedback" [tooltip]="errors">
                <ng-template #errors>
                  <div *ngIf="f.hasError('pattern')">Require 5 digits.</div>
                  <div *ngFor="let e of f.getError('server')">{{ e }}</div>
                </ng-template>
              </span>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="formGroup.get('referred_for') as g">
      <div class="hr-divider hr-divider-lg font-variant-normal m-y-md">
        <h3 class="hr-divider-content hr-divider-heading m-b-0">
          For Consideration for Consultation and/or
          <b>Endodontic</b>
          Treatment
        </h3>
      </div>
      <div class="col-sm-5 col-sm-offset-1">
        <h4>Referred for the Following</h4>
        <div class="form-group has-feedback" *ngIf="g.get('consultation_and_diagnosis') as f">
          <div class="checkbox custom-control custom-checkbox">
            <label>
              <input type="checkbox" [formControl]="$any(f)" />
              <span class="custom-control-indicator"></span>
              Consultation & Diagnosis
            </label>
          </div>
        </div>
        <div class="form-group has-feedback" *ngIf="g.get('root_canal_treatment') as f">
          <div class="checkbox custom-control custom-checkbox">
            <label>
              <input type="checkbox" [formControl]="$any(f)" />
              <span class="custom-control-indicator"></span>
              Root Canal Treatment
            </label>
          </div>
        </div>
        <div class="form-group has-feedback" *ngIf="g.get('re_treatment') as f">
          <div class="checkbox custom-control custom-checkbox">
            <label>
              <input type="checkbox" [formControl]="$any(f)" />
              <span class="custom-control-indicator"></span>
              Re-Treatment
            </label>
          </div>
        </div>
        <div class="form-group has-feedback" *ngIf="g.get('leave_post_space') as f">
          <div class="checkbox custom-control custom-checkbox">
            <label>
              <input type="checkbox" [formControl]="$any(f)" />
              <span class="custom-control-indicator"></span>
              Leave Post Space
            </label>
          </div>
        </div>
        <div class="form-group has-feedback" *ngIf="g.get('apicoectomy_retrograde') as f">
          <div class="checkbox custom-control custom-checkbox">
            <label>
              <input type="checkbox" [formControl]="$any(f)" />
              <span class="custom-control-indicator"></span>
              Apicoectomy / Retrograde
            </label>
          </div>
        </div>
        <div class="form-group has-feedback" *ngIf="g.get('apicoectomy_retrofilling') as f">
          <div class="checkbox custom-control custom-checkbox">
            <label>
              <input type="checkbox" [formControl]="$any(f)" />
              <span class="custom-control-indicator"></span>
              Apicoectomy / Retrofilling
            </label>
          </div>
        </div>
        <div class="form-group has-feedback" *ngIf="g.get('pulp_exposure') as f">
          <div class="checkbox custom-control custom-checkbox">
            <label>
              <input type="checkbox" [formControl]="$any(f)" />
              <span class="custom-control-indicator"></span>
              Pulp Exposure
            </label>
          </div>
        </div>
        <div class="form-group has-feedback" *ngIf="g.get('remove_post') as f">
          <div class="checkbox custom-control custom-checkbox">
            <label>
              <input type="checkbox" [formControl]="$any(f)" />
              <span class="custom-control-indicator"></span>
              Remove Post
            </label>
          </div>
        </div>
        <div class="form-group has-feedback" *ngIf="g.get('buildup') as f">
          <div class="checkbox custom-control custom-checkbox">
            <label>
              <input type="checkbox" [formControl]="$any(f)" />
              <span class="custom-control-indicator"></span>
              Buildup
            </label>
          </div>
        </div>
        <div class="form-group has-feedback" *ngIf="g.get('post_and_crown_buildup') as f">
          <div class="checkbox custom-control custom-checkbox">
            <label>
              <input type="checkbox" [formControl]="$any(f)" />
              <span class="custom-control-indicator"></span>
              Post and Crown Buildup
            </label>
          </div>
        </div>
        <div class="form-group has-feedback" *ngIf="g.get('tooth_is_open_for_drainage') as f">
          <div class="checkbox custom-control custom-checkbox">
            <label>
              <input type="checkbox" [formControl]="$any(f)" />
              <span class="custom-control-indicator"></span>
              Tooth is Open for Drainage
            </label>
          </div>
        </div>
        <div class="form-group has-feedback" *ngIf="g.get('endodontic_microsurgery') as f">
          <div class="checkbox custom-control custom-checkbox">
            <label>
              <input type="checkbox" [formControl]="$any(f)" />
              <span class="custom-control-indicator"></span>
              Endodontic Microsurgery
            </label>
          </div>
        </div>
      </div>
      <div class="col-sm-5 col-sm-offset-1">
        <h4>Other Information</h4>
        <div class="form-group has-feedback" *ngIf="g.get('please_send_additional_referral_pads') as f">
          <div class="checkbox custom-control custom-checkbox">
            <label>
              <input type="checkbox" [formControl]="$any(f)" />
              <span class="custom-control-indicator"></span>
              Please send additional referral pads
            </label>
          </div>
        </div>
        <div class="form-group has-feedback" *ngIf="g.get('please_call_patient_to_arrange_appt') as f">
          <div class="checkbox custom-control custom-checkbox">
            <label>
              <input type="checkbox" [formControl]="$any(f)" />
              <span class="custom-control-indicator"></span>
              Please call patient to arrange appt
            </label>
          </div>
        </div>
        <div class="form-group has-feedback" *ngIf="g.get('patient_will_call_you_to_arrange_appt') as f">
          <div class="checkbox custom-control custom-checkbox">
            <label>
              <input type="checkbox" [formControl]="$any(f)" />
              <span class="custom-control-indicator"></span>
              Patient will call you to arrange appt
            </label>
          </div>
        </div>
        <div class="form-group m-t" *ngIf="g.get('crown_or_bridge_is_cemented') as f">
          <label>Crown / Bridge is Cemented</label>
          <div>
            <div class="radio-inline custom-control custom-radio">
              <label>
                <input type="radio" [formControl]="$any(f)" name="crown-or-bridge-is-cemented" [value]="true" />
                <span class="custom-control-indicator"></span>
                Yes
              </label>
            </div>
            <div class="radio-inline custom-control custom-radio">
              <label>
                <input type="radio" [formControl]="$any(f)" name="crown-or-bridge-is-cemented" [value]="false" />
                <span class="custom-control-indicator"></span>
                No
              </label>
            </div>
          </div>
        </div>
        <div class="form-group m-t" *ngIf="g.get('please_call_me') as f">
          <label>Please Call Me</label>
          <div>
            <div class="radio-inline custom-control custom-radio">
              <label>
                <input type="radio" [formControl]="$any(f)" name="please-call-me" [value]="true" />
                <span class="custom-control-indicator"></span>
                Yes
              </label>
            </div>
            <div class="radio-inline custom-control custom-radio">
              <label>
                <input type="radio" [formControl]="$any(f)" name="please-call-me" [value]="false" />
                <span class="custom-control-indicator"></span>
                No
              </label>
            </div>
          </div>
        </div>
        <h4>Radiographs/Clinical Photos</h4>
        <div class="form-group has-feedback" *ngIf="g.get('xray') as f">
          <div class="radio custom-control custom-radio">
            <label>
              <input type="radio" [formControl]="$any(f)" name="xray" value="attached" />
              <span class="custom-control-indicator"></span>
              <span class="custom-control-indicator"></span>
              Attached to Referral
            </label>
          </div>
          <div class="radio custom-control custom-radio">
            <label>
              <input type="radio" [formControl]="$any(f)" name="xray" value="given_to_patient" />
              <span class="custom-control-indicator"></span>
              Given to Patient
            </label>
          </div>
          <div class="radio custom-control custom-radio">
            <label>
              <input type="radio" [formControl]="$any(f)" name="xray" value="please_take" />
              <span class="custom-control-indicator"></span>
              Please Take
            </label>
          </div>
          <div class="radio custom-control custom-radio">
            <label>
              <input type="radio" [formControl]="$any(f)" name="xray" value="no" />
              <span class="custom-control-indicator"></span>
              No X-Ray
            </label>
          </div>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="formGroup.get('referred_for')?.get('tooth_numbers') as g">
      <div class="hr-divider hr-divider-lg font-variant-normal m-y">
        <h3 class="hr-divider-content hr-divider-heading">Please mark teeth or area to be treated</h3>
      </div>
      <div class="tooth-picker">
        <img class="thumbnail" src="/assets/tooth-picker.png" />
        <div class="checkbox-inline custom-control custom-checkbox" style="top: 115px; left: 66px">
          <label *ngIf="g.get('1') as f">
            <input type="checkbox" [formControl]="$any(f)" />
            <span class="custom-control-indicator"></span>
          </label>
        </div>
        <div class="checkbox-inline custom-control custom-checkbox" style="top: 115px; left: 93px">
          <label *ngIf="g.get('2') as f">
            <input type="checkbox" [formControl]="$any(f)" />
            <span class="custom-control-indicator"></span>
          </label>
        </div>
        <div class="checkbox-inline custom-control custom-checkbox" style="top: 115px; left: 133px">
          <label *ngIf="g.get('3') as f">
            <input type="checkbox" [formControl]="$any(f)" />
            <span class="custom-control-indicator"></span>
          </label>
        </div>
        <div class="checkbox-inline custom-control custom-checkbox" style="top: 115px; left: 169px">
          <label *ngIf="g.get('4') as f">
            <input type="checkbox" [formControl]="$any(f)" />
            <span class="custom-control-indicator"></span>
          </label>
        </div>
        <div class="checkbox-inline custom-control custom-checkbox" style="top: 115px; left: 197px">
          <label *ngIf="g.get('5') as f">
            <input type="checkbox" [formControl]="$any(f)" />
            <span class="custom-control-indicator"></span>
          </label>
        </div>
        <div class="checkbox-inline custom-control custom-checkbox" style="top: 115px; left: 228px">
          <label *ngIf="g.get('6') as f">
            <input type="checkbox" [formControl]="$any(f)" />
            <span class="custom-control-indicator"></span>
          </label>
        </div>
        <div class="checkbox-inline custom-control custom-checkbox" style="top: 115px; left: 260px">
          <label *ngIf="g.get('7') as f">
            <input type="checkbox" [formControl]="$any(f)" />
            <span class="custom-control-indicator"></span>
          </label>
        </div>
        <div class="checkbox-inline custom-control custom-checkbox" style="top: 115px; left: 292px">
          <label *ngIf="g.get('8') as f">
            <input type="checkbox" [formControl]="$any(f)" />
            <span class="custom-control-indicator"></span>
          </label>
        </div>
        <div class="checkbox-inline custom-control custom-checkbox" style="top: 115px; left: 327px">
          <label *ngIf="g.get('9') as f">
            <input type="checkbox" [formControl]="$any(f)" />
            <span class="custom-control-indicator"></span>
          </label>
        </div>
        <div class="checkbox-inline custom-control custom-checkbox" style="top: 115px; left: 358px">
          <label *ngIf="g.get('10') as f">
            <input type="checkbox" [formControl]="$any(f)" />
            <span class="custom-control-indicator"></span>
          </label>
        </div>
        <div class="checkbox-inline custom-control custom-checkbox" style="top: 115px; left: 389px">
          <label *ngIf="g.get('11') as f">
            <input type="checkbox" [formControl]="$any(f)" />
            <span class="custom-control-indicator"></span>
          </label>
        </div>
        <div class="checkbox-inline custom-control custom-checkbox" style="top: 115px; left: 421px">
          <label *ngIf="g.get('12') as f">
            <input type="checkbox" [formControl]="$any(f)" />
            <span class="custom-control-indicator"></span>
          </label>
        </div>
        <div class="checkbox-inline custom-control custom-checkbox" style="top: 115px; left: 451px">
          <label *ngIf="g.get('13') as f">
            <input type="checkbox" [formControl]="$any(f)" />
            <span class="custom-control-indicator"></span>
          </label>
        </div>
        <div class="checkbox-inline custom-control custom-checkbox" style="top: 115px; left: 485px">
          <label *ngIf="g.get('14') as f">
            <input type="checkbox" [formControl]="$any(f)" />
            <span class="custom-control-indicator"></span>
          </label>
        </div>
        <div class="checkbox-inline custom-control custom-checkbox" style="top: 115px; left: 525px">
          <label *ngIf="g.get('15') as f">
            <input type="checkbox" [formControl]="$any(f)" />
            <span class="custom-control-indicator"></span>
          </label>
        </div>
        <div class="checkbox-inline custom-control custom-checkbox" style="top: 115px; left: 560px">
          <label *ngIf="g.get('16') as f">
            <input type="checkbox" [formControl]="$any(f)" />
            <span class="custom-control-indicator"></span>
          </label>
        </div>
        <div class="checkbox-inline custom-control custom-checkbox" style="top: 137px; left: 56px">
          <label *ngIf="g.get('32') as f">
            <input type="checkbox" [formControl]="$any(f)" />
            <span class="custom-control-indicator"></span>
          </label>
        </div>
        <div class="checkbox-inline custom-control custom-checkbox" style="top: 137px; left: 93px">
          <label *ngIf="g.get('31') as f">
            <input type="checkbox" [formControl]="$any(f)" />
            <span class="custom-control-indicator"></span>
          </label>
        </div>
        <div class="checkbox-inline custom-control custom-checkbox" style="top: 137px; left: 133px">
          <label *ngIf="g.get('30') as f">
            <input type="checkbox" [formControl]="$any(f)" />
            <span class="custom-control-indicator"></span>
          </label>
        </div>
        <div class="checkbox-inline custom-control custom-checkbox" style="top: 137px; left: 169px">
          <label *ngIf="g.get('29') as f">
            <input type="checkbox" [formControl]="$any(f)" />
            <span class="custom-control-indicator"></span>
          </label>
        </div>
        <div class="checkbox-inline custom-control custom-checkbox" style="top: 137px; left: 197px">
          <label *ngIf="g.get('28') as f">
            <input type="checkbox" [formControl]="$any(f)" />
            <span class="custom-control-indicator"></span>
          </label>
        </div>
        <div class="checkbox-inline custom-control custom-checkbox" style="top: 137px; left: 228px">
          <label *ngIf="g.get('27') as f">
            <input type="checkbox" [formControl]="$any(f)" />
            <span class="custom-control-indicator"></span>
          </label>
        </div>
        <div class="checkbox-inline custom-control custom-checkbox" style="top: 137px; left: 260px">
          <label *ngIf="g.get('26') as f">
            <input type="checkbox" [formControl]="$any(f)" />
            <span class="custom-control-indicator"></span>
          </label>
        </div>
        <div class="checkbox-inline custom-control custom-checkbox" style="top: 137px; left: 292px">
          <label *ngIf="g.get('25') as f">
            <input type="checkbox" [formControl]="$any(f)" />
            <span class="custom-control-indicator"></span>
          </label>
        </div>
        <div class="checkbox-inline custom-control custom-checkbox" style="top: 137px; left: 327px">
          <label *ngIf="g.get('24') as f">
            <input type="checkbox" [formControl]="$any(f)" />
            <span class="custom-control-indicator"></span>
          </label>
        </div>
        <div class="checkbox-inline custom-control custom-checkbox" style="top: 137px; left: 358px">
          <label *ngIf="g.get('23') as f">
            <input type="checkbox" [formControl]="$any(f)" />
            <span class="custom-control-indicator"></span>
          </label>
        </div>
        <div class="checkbox-inline custom-control custom-checkbox" style="top: 137px; left: 389px">
          <label *ngIf="g.get('22') as f">
            <input type="checkbox" [formControl]="$any(f)" />
            <span class="custom-control-indicator"></span>
          </label>
        </div>
        <div class="checkbox-inline custom-control custom-checkbox" style="top: 137px; left: 421px">
          <label *ngIf="g.get('21') as f">
            <input type="checkbox" [formControl]="$any(f)" />
            <span class="custom-control-indicator"></span>
          </label>
        </div>
        <div class="checkbox-inline custom-control custom-checkbox" style="top: 137px; left: 451px">
          <label *ngIf="g.get('20') as f">
            <input type="checkbox" [formControl]="$any(f)" />
            <span class="custom-control-indicator"></span>
          </label>
        </div>
        <div class="checkbox-inline custom-control custom-checkbox" style="top: 137px; left: 485px">
          <label *ngIf="g.get('19') as f">
            <input type="checkbox" [formControl]="$any(f)" />
            <span class="custom-control-indicator"></span>
          </label>
        </div>
        <div class="checkbox-inline custom-control custom-checkbox" style="top: 137px; left: 525px">
          <label *ngIf="g.get('18') as f">
            <input type="checkbox" [formControl]="$any(f)" />
            <span class="custom-control-indicator"></span>
          </label>
        </div>
        <div class="checkbox-inline custom-control custom-checkbox" style="top: 137px; left: 560px">
          <label *ngIf="g.get('17') as f">
            <input type="checkbox" [formControl]="$any(f)" />
            <span class="custom-control-indicator"></span>
          </label>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="formGroup.get('referred_for') as g">
      <div class="hr-divider hr-divider-lg font-variant-normal m-y-md">
        <h3 class="hr-divider-content hr-divider-heading">Remarks or Special Instructions</h3>
      </div>
      <div class="col-sm-12">
        <div class="form-group m-b" *ngIf="g.get('description') as f">
          <textarea class="form-control" [formControl]="$any(f)" rows="10"></textarea>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="hr-divider hr-divider-lg font-variant-normal m-y">
        <h3 class="hr-divider-content hr-divider-heading">Attach Images</h3>
      </div>
      <div class="col-xs-12 col-sm-4 col-md-3" *ngIf="formGroup.enabled">
        <div class="thumbnail" [ngSwitch]="isBeignUpdated">
          <ng-container *ngSwitchCase="true">
            <!-- prettier-ignore -->
            <div class="image" [class.dragover]="dragover" accept="image/*" multiple="1" ngfDrop [(validDrag)]="dragover" [capturePaste]="true"
              (filesChange)="onImagesAdded($event)">
              <div class="preview">Drop Images</div>
            </div>
            <label class="btn btn-block btn-primary" style="margin-top: 1px">
              Select Images
              <input type="file" accept="image/*" multiple ngfSelect (filesChange)="onImagesAdded($event)" />
            </label>
          </ng-container>
          <ng-container *ngSwitchCase="false">
            <div class="image text-center p-y-md">
              <span class="d-ib" style="line-height: normal; vertical-align: middle">
                You need to first save the referral before attaching images.
              </span>
            </div>
          </ng-container>
        </div>
      </div>
      <div class="col-xs-12 col-sm-4 col-md-3" *ngFor="let image of formGroup.get('images')?.value; trackBy:trackById">
        <div class="thumbnail">
          <div class="image" [ngSwitch]="image.id > 0">
            <div *ngSwitchCase="true" class="btn-group">
              <button type="button" class="btn" (click)="onImagesClicked('view', image)">
                <i class="icon icon-eye"></i>
              </button>
              <button type="button" class="btn" (click)="onImagesClicked('download', image)">
                <i class="icon icon-download"></i>
              </button>
              <button type="button" class="btn" (click)="onImagesClicked('delete', image)" *ngIf="formGroup.enabled">
                <i class="icon icon-trash"></i>
              </button>
            </div>
            <div class="sk-wave" *ngSwitchCase="false">
              <div class="sk-wave-rect"></div>
              <div class="sk-wave-rect"></div>
              <div class="sk-wave-rect"></div>
              <div class="sk-wave-rect"></div>
              <div class="sk-wave-rect"></div>
            </div>
            <div class="preview" [style.background-image]="image.archive_thumb.url | async | trust:'css'"></div>
          </div>
          <h5>{{ image.name }}</h5>
        </div>
      </div>
    </div>
  </fieldset>
  <div class="row">
    <hr class="m-t-0" />
    <div class="col-sm-12">
      <div class="pull-right">
        <a class="btn btn-primary-outline btn-wide" [routerLink]="['..']">Back</a>
        <button type="button" class="btn btn-wide btn-primary m-l" (click)="onSubmit()" *ngIf="formGroup.enabled">Save</button>
      </div>
    </div>
  </div>
</ng-container>

<router-outlet></router-outlet>
