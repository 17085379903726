import { HttpClient } from "@angular/common/http"
import { Injectable } from "@angular/core"

const api = "/api/v3/~referrals-external/token"

@Injectable({ providedIn: "root" })
export class ServiceToken {
  constructor(private http: HttpClient) {}

  unsign(value: string) {
    type ApiResponse = UnsignResponse
    return this.http.post<ApiResponse>(`${api}/unsign`, { value })
  }
}

export type UnsignResponse = {
  url: string
  user: {
    id: number
    email: string
    name: string
    has_password: boolean
    practice: number
    token?: string
  }
}
