<nav class="navbar navbar-default navbar-fixed-top">
  <div class="container">
    <ng-container [ngSwitch]="user.is_authenticated">
      <ng-container *ngSwitchCase="true">
        <div class="navbar-header">
          <button type="button" class="navbar-toggle" (click)="isCollapsed = !isCollapsed">
            <span class="sr-only">Toggle navigation</span>
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
          </button>
          <a class="navbar-brand" routerLink="/referrals">
            <img [attr.src]="user.practice.picture.url | async | trust" class="img-rounded" />
            {{ user.practice.name }}
          </a>
        </div>
        <div class="navbar-collapse" [collapse]="isCollapsed">
          <ul class="nav navbar-nav navbar-right">
            <!-- <app-idle-timer></app-idle-timer> -->
            <li class="dropdown" dropdown [autoClose]="isCollapsed ? true : false" placement="bottom right">
              <a href class="dropdown-toggle" dropdownToggle (click)="false">
                <span class="icon icon-user"></span>
                <span class="caret"></span>
              </a>
              <ul class="dropdown-menu dropdown-menu-right" *dropdownMenu>
                <li>
                  <span class="text-muted">{{ user.name }}</span>
                </li>
                <li>
                  <span class="text-muted">{{ user.email }}</span>
                </li>
                <li class="divider"></li>
                <li>
                  <a href (click)="onClickMenuItem('password-change')">Change Password</a>
                </li>
                <li>
                  <a href (click)="onClickMenuItem('welcome')">Welcome Video</a>
                </li>
                <li>
                  <a href (click)="onClickMenuItem('license')">Licenses</a>
                </li>
                <li>
                  <a href (click)="onClickMenuItem('feedback')">Send feedback</a>
                </li>
                <li class="divider"></li>
                <li>
                  <a href (click)="onClickMenuItem('logout')">Sign out</a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </ng-container>
      <ng-container *ngSwitchCase="false">
        <div class="navbar-header">
          <span class="navbar-brand">
            <img src="assets/favicon.png" class="img-rounded" />
          </span>
        </div>
      </ng-container>
    </ng-container>
  </div>
</nav>
