<div class="container m-t-lg">
  <div class="row">
    <div class="col-md-6 col-md-offset-3 well well-z" [ngSwitch]="errorCode">
      <div *ngSwitchCase="404">
        <div class="icon icon-emoji-sad"></div>
        <h2 class="text-center">Not found</h2>
        <h4 class="text-center m-b">We couldn't find what you're looking for</h4>
        <h5 class="text-center">
          Use your browser"s back button and check that you're in the right place.
          <br />
          If you need immediate assistance, please send us a
          <a href="mailto:support@dentalemr.com">email</a>
          instead.
          <br />
          We apologize for any inconvenience.
        </h5>
      </div>
    </div>
  </div>
</div>
