import { HttpClient } from "@angular/common/http"

export class Picture {
  url: Promise<string>
  blob?: Blob

  constructor(value: any, http?: HttpClient, fallback = "assets/practice.png") {
    this.url = new Promise((resolve) => {
      if (value) {
        if (http) {
          http.get(value, { responseType: "blob" }).subscribe(
            (blob) => {
              this.blob = blob
              resolve(URL.createObjectURL(blob))
            },
            () => {
              resolve(fallback)
            },
          )
        } else {
          resolve(value)
        }
      } else {
        resolve(fallback)
      }
    })
  }

  async dataurl() {
    const fallback = [
      "data:image/png;base64",
      "iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mO8+x8AAr8B3gzOjaQAAAAASUVORK5CYII=",
    ].join()
    return this.url.then(() => {
      return new Promise<string>((resolve) => {
        if (this.blob) {
          const reader = new FileReader()
          reader.onloadend = function () {
            if (reader.result) {
              resolve(reader.result as string)
            } else {
              resolve(fallback)
            }
          }
          reader.readAsDataURL(this.blob)
        } else {
          resolve(fallback)
        }
      })
    })
  }
}
