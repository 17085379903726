import { ActivatedRoute } from "@angular/router"
import { Component } from "@angular/core"
import { FormBuilder } from "@angular/forms"
import { OnInit } from "@angular/core"
import { PASSWORD_PATTERN } from "app/patterns"
import { PasswordSetFormGroupValidator } from "app/forms/validators"
import type { Practice } from "app/services/practice"
import { ResponseStatus } from "app/enums/response-status"
import { Router } from "@angular/router"
import { ServicePractice } from "app/services/practice"
import { ServiceUser } from "app/services/user"
import { ToastrService } from "ngx-toastr"
import { Validators } from "@angular/forms"

@Component({ styleUrls: ["../form.scss"], templateUrl: "index.html" })
export class RoutePasswordSetComponent implements OnInit {
  formGroup = this.formBuilder.group(
    {
      id: [null, [Validators.required]],
      token: [null, [Validators.required]],
      password: ["", [Validators.required, Validators.pattern(PASSWORD_PATTERN)]],

      password_rep: [""],
      password_txt: [false],
    },
    {
      validators: PasswordSetFormGroupValidator("password", "password_rep", "password_txt"),
    },
  )

  loading = false

  params: { email: string; name: string; practice: Practice | null } = { email: "", name: "", practice: null }

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private servicePractice: ServicePractice,
    private serviceUser: ServiceUser,
    private toastr: ToastrService,
  ) {}

  ngOnInit() {
    this.route.params.subscribe(({ id, email, name, practice, token }) => {
      this.formGroup.patchValue({ id })
      if (email) {
        this.params.email = email
      }
      if (name) {
        this.params.name = name
      }
      if (practice) {
        this.servicePractice.retrieve(practice).subscribe({
          next: (resp) => (this.params.practice = resp),
          error: () => (this.params.practice = this.servicePractice.defaultPractice),
        })
      }
      this.formGroup.patchValue({ token })
    })
  }

  onSubmit() {
    if (this.formGroup.valid) {
      this.loading = true
      const { id, token, password } = this.formGroup.value
      this.serviceUser.passwordSet({ id, token, password }).subscribe({
        next: (resp) => {
          this.loading = false
          this.router.navigate([this.route.snapshot.queryParams["next"] || "/referrals/sent"])
        },
        error: (resp) => {
          this.loading = false
          const {
            error: { errors = [], ...fields },
            status,
            statusText,
          } = resp
          // console.error(JSON.stringify({ status, statusText, errors, fields }, null, 2)) //-> only-for-testing
          if (status === ResponseStatus.BAD_REQUEST) {
            for (let field in fields) {
              const f = this.formGroup.get(field)
              if (f) {
                f.setErrors({ ...f.errors, server: fields[field] })
              }
            }
            this.formGroup.setErrors({ ...this.formGroup.errors, server: errors })
          } else {
            this.toastr.error("We will investigate the problem.", `${statusText}`)
            throw new Error(resp)
          }
        },
      })
    }
  }
}
