import { BsModalRef } from "ngx-bootstrap/modal"
import { Component } from "@angular/core"
import { HttpClient } from "@angular/common/http"
import { OnInit } from "@angular/core"

@Component({ templateUrl: "index.html" })
export class ModalLicenseComponent implements OnInit {
  text: string = ""

  constructor(public modal: BsModalRef, private http: HttpClient) {}

  ngOnInit() {
    this.http.get("/3rdpartylicenses.txt", { responseType: "text" }).subscribe(
      (text: any) => {
        this.text = text
      },
      () => {
        this.text = ""
      },
    )
  }
}
