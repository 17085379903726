import { HttpClient } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { Renderer2 } from "@angular/core"
import { RendererFactory2 } from "@angular/core"
import { concatMap } from "rxjs/operators"
import { map } from "rxjs/operators"
import { tap } from "rxjs/operators"

const api = "/api/v3/~referrals-external/report-image"

@Injectable({ providedIn: "root" })
export class ServiceReportImage {
  private renderer: Renderer2

  constructor(private http: HttpClient, private rendererFactory: RendererFactory2) {
    this.renderer = this.rendererFactory.createRenderer(null, null)
  }

  download(id: number) {
    type ApiResponse = DownloadResponse
    return this.http.get<ApiResponse>(`${api}/${id}/download`).pipe(
      concatMap(({ name, url }) => {
        return this.http.get(url, { responseType: "blob" }).pipe(map((blob) => ({ name, blob })))
      }),
      tap(({ name, blob }) => {
        const a = this.renderer.createElement("a")
        this.renderer.setAttribute(a, "download", name)
        this.renderer.setAttribute(a, "href", URL.createObjectURL(blob))
        this.renderer.appendChild(document.body, a)
        a.click()
        URL.revokeObjectURL(a.getAttribute("href")!)
        this.renderer.removeChild(document.body, a)
      }),
    )
  }

  view(id: number) {
    type ApiResponse = ViewResponse
    return this.http.get<ApiResponse>(`${api}/${id}/download`).pipe(
      concatMap(({ name, url }) => {
        return this.http.get(url, { responseType: "blob" }).pipe(map((blob) => ({ name, blob })))
      }),
      tap(({ blob }) => {
        const a = this.renderer.createElement("a")
        this.renderer.setAttribute(a, "href", URL.createObjectURL(blob))
        this.renderer.setAttribute(a, "target", "_blank")
        this.renderer.appendChild(document.body, a)
        a.click()
        URL.revokeObjectURL(a.getAttribute("href")!)
        this.renderer.removeChild(document.body, a)
      }),
    )
  }
}

export type DownloadResponse = {
  name: string
  url: string
}

export type ViewResponse = {
  name: string
  url: string
}
