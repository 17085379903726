import { AbstractControl } from "@angular/forms"
import { ValidatorFn } from "@angular/forms"

export function PasswordSetFormGroupValidator(pnew_name: string, prep_name: string, ptxt_name: string): ValidatorFn {
  return (formGroup: AbstractControl) => {
    const pnew = formGroup.get(pnew_name)!
    const prep = formGroup.get(prep_name)!
    const ptxt = formGroup.get(ptxt_name)!
    if (ptxt.value) {
      prep.disable({ onlySelf: true })
    } else {
      prep.enable({ onlySelf: true })
    }
    if (!ptxt.value && pnew.value && pnew.value != prep.value) {
      prep.setErrors({ mismatch: true })
    } else {
      prep.setErrors(null)
    }
    return null
  }
}
