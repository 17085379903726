import { Component } from "@angular/core"
import { OnInit } from "@angular/core"
import { Router } from "@angular/router"
import { ServiceModal } from "app/services/modal"
import { ServiceUser } from "app/services/user"

@Component({ selector: "app-header", styleUrls: ["index.scss"], templateUrl: "index.html" })
export class HeaderComponent implements OnInit {
  isCollapsed = true

  user = this.serviceUser.current.getValue()

  constructor(private router: Router, private serviceModal: ServiceModal, private serviceUser: ServiceUser) {}

  ngOnInit() {
    this.serviceUser.current.subscribe((user) => (this.user = user))
  }

  onClickMenuItem(kind: "logout" | "feedback" | "license" | "password-change" | "welcome") {
    switch (kind) {
      case "logout":
        this.serviceUser.logout().subscribe(() => this.router.navigate(["/login"]))
        break
      case "feedback":
        this.serviceModal.feedback()
        break
      case "license":
        this.serviceModal.license()
        break
      case "password-change":
        this.serviceModal.passwordChange()
        break
      case "welcome":
        this.serviceModal.welcome()
        break
    }
    return false
  }
}
