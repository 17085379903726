import { HttpClient } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { Picture } from "app/picture.class"
import { Renderer2 } from "@angular/core"
import { RendererFactory2 } from "@angular/core"
import { concatMap } from "rxjs/operators"
import { forkJoin } from "rxjs"
import { map } from "rxjs/operators"
import { of } from "rxjs"
import { tap } from "rxjs/operators"

const api = "/api/v3/~referrals-external/referral-image"

@Injectable({ providedIn: "root" })
export class ServiceReferralImage {
  private renderer: Renderer2

  constructor(private http: HttpClient, private rendererFactory: RendererFactory2) {
    this.renderer = this.rendererFactory.createRenderer(null, null)
  }

  create(files: File[], data: any) {
    type ApiCreateResponse = {
      id: number
    }
    type ApiUploadResponse = {
      archive: {
        data: {}
        name: string
        url: string
      }
      archive_thumb: {
        data: {}
        name: string
        url: string
      }
    }
    return of(
      ...files.map((file) => {
        const image: CreateResponse = {
          id: null,
          name: file.name,
          archive_thumb: new Picture(URL.createObjectURL(file), undefined, "/assets/no-thumb.jpg"),
        }
        forkJoin({
          file_thumb: new Promise<File>((resolve) => {
            const width = 640
            try {
              const img = this.renderer.createElement("img") as HTMLImageElement
              img.addEventListener("error", () => resolve(file))
              img.addEventListener("load", () => {
                if (img.width <= width) {
                  return resolve(file)
                }
                const ratio = img.width / img.height
                const height = parseInt(`${width / ratio}`, 10)
                const canvas = this.renderer.createElement("canvas") as HTMLCanvasElement
                canvas.setAttribute("width", `${width}`)
                canvas.setAttribute("height", `${height}`)
                canvas.getContext("2d")?.drawImage(img, 0, 0, width, height)
                canvas.toBlob((blob) => {
                  if (blob) {
                    resolve(new File([blob], file.name, { type: file.type }))
                    URL.revokeObjectURL(img.getAttribute("src")!)
                  }
                }, file.type)
              })
              img.setAttribute("src", URL.createObjectURL(file))
            } catch (e) {
              resolve(file)
            }
          }),
          upload: this.http.post<ApiUploadResponse>(`${api}/upload`, { name: file.name }),
        })
          .pipe(
            concatMap(({ file_thumb, upload: { archive, archive_thumb } }) =>
              forkJoin({
                archive: this.http
                  .post(archive.url, formData({ ...archive.data, file: file }))
                  .pipe(map(() => archive.name)),
                archive_thumb: this.http
                  .post(archive_thumb.url, formData({ ...archive_thumb.data, file: file_thumb }))
                  .pipe(map(() => archive_thumb.name)),
              }),
            ),
          )
          .subscribe(({ archive, archive_thumb }) => {
            this.http.post<ApiCreateResponse>(`${api}`, { archive, archive_thumb, ...data }).subscribe(({ id }) => {
              image.id = id
            })
          })
        return image
      }),
    )
  }

  delete(id: number) {
    type ApiResponse = DeleteResponse
    return this.http.delete<ApiResponse>(`${api}/${id}`)
  }

  download(id: number) {
    type ApiResponse = DownloadResponse
    return this.http.get<ApiResponse>(`${api}/${id}/download`).pipe(
      concatMap(({ name, url }) => {
        return this.http.get(url, { responseType: "blob" }).pipe(map((blob) => ({ name, blob })))
      }),
      tap(({ name, blob }) => {
        const a = this.renderer.createElement("a")
        this.renderer.setAttribute(a, "download", name)
        this.renderer.setAttribute(a, "href", URL.createObjectURL(blob))
        this.renderer.appendChild(document.body, a)
        a.click()
        URL.revokeObjectURL(a.getAttribute("href")!)
        this.renderer.removeChild(document.body, a)
      }),
    )
  }

  view(id: number) {
    type ApiResponse = ViewResponse
    return this.http.get<ApiResponse>(`${api}/${id}/download`).pipe(
      concatMap(({ name, url }) => {
        return this.http.get(url, { responseType: "blob" }).pipe(map((blob) => ({ name, blob })))
      }),
      tap(({ blob }) => {
        const a = this.renderer.createElement("a")
        this.renderer.setAttribute(a, "href", URL.createObjectURL(blob))
        this.renderer.setAttribute(a, "target", "_blank")
        this.renderer.appendChild(document.body, a)
        a.click()
        URL.revokeObjectURL(a.getAttribute("href")!)
        this.renderer.removeChild(document.body, a)
      }),
    )
  }
}

export type CreateResponse = {
  id: number | null
  name: string
  archive_thumb: Picture
}

export type DeleteResponse = {}

export type DownloadResponse = {
  name: string
  url: string
}

export type ViewResponse = {
  name: string
  url: string
}

export function formData(data: { [key: string]: string | Blob } = {}) {
  const fd = new FormData()
  Object.entries(data).forEach(([key, value]) => fd.set(key, value))
  return fd
}
