<ng-container *ngIf="route.children.length === 0">
  <div class="dashhead">
    <div class="dashhead-titles">
      <h6 class="dashhead-subtitle">
        <span [hidden]="!loading">loading...</span>
        &nbsp;
      </h6>
      <h3 class="dashhead-title">Referrals</h3>
    </div>
    <div class="dashhead-toolbar">
      <div class="dashhead-toolbar-item">
        <div class="custom-control">
          <span class="pr">Sort by</span>
        </div>
      </div>
      <div class="dashhead-toolbar-item" *ngIf="formGroup.get('orderby')?.get('patient') as f">
        <div class="checkbox-inline custom-control custom-checkbox">
          <label>
            <input type="checkbox" [formControl]="$any(f)" />
            <span class="custom-control-indicator"></span>
            Patient
          </label>
        </div>
      </div>
      <div class="dashhead-toolbar-divider hidden-xs"></div>
      <div class="dashhead-toolbar-item">
        <div class="custom-control">
          <span class="pr">Limit to</span>
        </div>
      </div>
      <div class="dashhead-toolbar-item" *ngIf="formGroup.get('limitto')?.get('uncompleted') as f">
        <div class="checkbox-inline custom-control custom-checkbox">
          <label>
            <input type="checkbox" [formControl]="$any(f)" />
            <span class="custom-control-indicator"></span>
            Uncompleted
          </label>
        </div>
      </div>
      <div class="dashhead-toolbar-item">
        <div class="custom-control">
          <span class="pl pr">and</span>
        </div>
      </div>
      <div class="dashhead-toolbar-item" *ngIf="formGroup.get('limitto')?.get('period') as f">
        <div class="radio-inline custom-control custom-radio">
          <label>
            <input type="radio" [formControl]="$any(f)" value="day" (click)="f.setValue(f.value === 'day' ? null : 'day')" />
            <span class="custom-control-indicator"></span>
            Day
          </label>
        </div>
        <div class="radio-inline custom-control custom-radio">
          <label btnRadio="week">
            <input type="radio" [formControl]="$any(f)" value="week" (click)="f.setValue(f.value === 'week' ? null : 'week')" />
            <span class="custom-control-indicator"></span>
            Week
          </label>
        </div>
        <div class="radio-inline custom-control custom-radio">
          <label btnRadio="month">
            <input type="radio" [formControl]="$any(f)" value="month" (click)="f.setValue(f.value === 'month' ? null : 'month')" />
            <span class="custom-control-indicator"></span>
            Month
          </label>
        </div>
      </div>
      <div class="dashhead-toolbar-divider hidden-xs"></div>
      <div class="dashhead-toolbar-item">
        <button class="btn btn-wide btn-default" type="button" (click)="list()">Reload</button>
      </div>
      <div class="dashhead-toolbar-divider hidden-xs"></div>
      <div class="dashhead-toolbar-item">
        <a class="btn btn-wide btn-primary" [routerLink]="['new']">Submit Referral</a>
      </div>
    </div>
  </div>
  <div class="table-responsive m-t" *ngIf="referrals">
    <table class="table">
      <thead>
        <tr>
          <th></th>
          <th>Date</th>
          <th></th>
          <th>Referrer</th>
          <th>On behalf Of Practice</th>
          <th>Patient</th>
          <th>Remarks</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let referral of referrals; trackBy:trackById" [class.success]="referral.is_completed">
          <td class="text-muted text-right">
            <span>{{ referral.id }}</span>
          </td>
          <td>
            <span>{{ referral.date | date:"MM/dd/yyyy" }}</span>
          </td>
          <td>
            <i class="icon icon-users" *ngIf="referral.isFromOther"></i>
          </td>
          <td>
            <span>{{ referral.ext_user.name }}</span>
          </td>
          <td>
            <span>{{ referral.ext_practice.name }}</span>
          </td>
          <td>
            <span>{{ referral.ext_patient.name }}</span>
          </td>
          <td>
            <span>{{ referral.referred_for.description }}</span>
          </td>
          <td>
            <a class="btn btn-link icon icon-new-message" [routerLink]="[referral.id]"></a>
            <ng-container *ngIf="referral.reports.exists">
              <a class="btn btn-link icon icon-newsletter" [class.text-danger]="referral.reports.unread" [routerLink]="[referral.id, 'reports']"></a>
            </ng-container>
          </td>
        </tr>
      </tbody>
      <tfoot *ngIf="referrals?.length === 0">
        <tr>
          <td colspan="8">
            <h4 class="text-center text-danger m-t-md">There are no Referrals. Try changing the filters.</h4>
          </td>
        </tr>
      </tfoot>
    </table>
  </div>
</ng-container>

<router-outlet></router-outlet>
