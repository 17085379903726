import { BehaviorSubject } from "rxjs"
import { HttpClient } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { Picture } from "app/picture.class"
import { catchError } from "rxjs/operators"
import { map } from "rxjs/operators"
import { skip } from "rxjs/operators"
import { tap } from "rxjs/operators"

const api = "/api/v3/~referrals-external/referral-user"

@Injectable({ providedIn: "root" })
export class ServiceUser {
  readonly current: BehaviorSubject<User>

  readonly defaultUser: User = {
    id: null,
    name: "AnonymousUser",
    email: "user@dentalemr.com",
    practice: {
      id: null,
      name: "DentalEMR",
      picture: new Picture(null),
    },
    show_welcome: false,
    is_authenticated: false,
  }

  constructor(private http: HttpClient) {
    this.current = new BehaviorSubject(this.defaultUser)
    this.current.pipe(skip(1)).subscribe((_user_) => {
      // console.log("https://github.com/DentalEMR/dentalemr-referrals/blob/master/src/app/app.service.ts#L36-L45")
    })
  }

  authenticated() {
    return this.http.get<UserResponse>(`${api}/authenticated`).pipe(this.map(), this.tap())
  }

  feedback(data: { content: string }) {
    return this.http.post<null>(`${api}/feedback`, data)
  }

  login(data: any) {
    return this.http.post<UserResponse>(`${api}/login`, data).pipe(this.fix(), this.map(), this.tap())
  }

  logout() {
    return this.http.post(`${api}/logout`, {}).pipe(
      tap(() => {
        this.current.next(this.defaultUser)
      }),
    )
  }

  passwordChange(data: { password_cur: string; password_new: string }) {
    return this.http.put(`${api}/password-change`, data)
  }

  passwordReset(data: { email: string }) {
    return this.http.post(`${api}/password-reset`, data)
  }

  passwordSet(data: { id: number; token: string; password: string }) {
    return this.http.post<UserResponse>(`${api}/password-set`, data).pipe(this.map(), this.tap())
  }

  update(data: { show_welcome: boolean }) {
    return this.http.put<UserResponse>(`${api}/current`, data).pipe(
      tap(({ show_welcome }) => {
        // this.current.next({ ...this.current.getValue(), show_welcome })
        this.current.getValue().show_welcome = show_welcome
      }),
    )
  }

  private fix() {
    return catchError<UserResponse, never>((resp) => {
      if (resp.error.practice?.required) {
        const practices: ErrorPracticeRequiredResponse[] = JSON.parse(resp.error.practice.required)
        resp.error.practice.required = practices.map<ErrorPracticeRequired>(
          ({
            password_valid, //
            picture,
            user_active,
            ...props
          }) => ({
            ...props,
            password_valid,
            picture: new Picture(picture, this.http),
            user_active,
            is_available: password_valid && user_active,
          }),
        )
      }
      throw resp
    })
  }

  private map() {
    return map<UserResponse, User>((resp) => {
      if (resp) {
        const {
          id, //
          first_name,
          last_name,
          practice,
          ...props
        } = resp
        return {
          ...props,
          id,
          name: `${first_name} ${last_name}`,
          practice: {
            id: practice.id,
            name: practice.name,
            picture: new Picture(practice.picture),
          },
          is_authenticated: true,
        }
      }
      return this.defaultUser
    })
  }

  private tap() {
    return tap<User>((user) => this.current.next(user))
  }
}

export type ErrorPracticeRequired = {
  id: number
  name: string
  password_valid: boolean
  picture: Picture
  user_active: boolean
  is_available: boolean
}

type ErrorPracticeRequiredResponse = {
  id: number
  name: string
  password_valid: boolean
  picture: string | null
  user_active: boolean
}

type User = {
  id: number | null
  email: string
  show_welcome: boolean
  name: string
  practice: {
    id: number | null
    name: string
    picture: Picture
  }
  is_authenticated: boolean
}

type UserResponse = {
  id: number | null
  email: string
  show_welcome: boolean
  first_name: string
  last_name: string
  practice: {
    id: number | null
    name: string
    picture: string
  }
}
