<div class="modal-body">
  <div class="sk-wave" *ngIf="loading">
    <div class="sk-wave-rect"></div>
    <div class="sk-wave-rect"></div>
    <div class="sk-wave-rect"></div>
    <div class="sk-wave-rect"></div>
    <div class="sk-wave-rect"></div>
  </div>
  <h3 class="text-center">Send feedback</h3>
  <form [formGroup]="formGroup">
    <div class="form-group has-feedback" *ngIf="formGroup.get('content') as f" [ngClass]="{'has-error': f.invalid}">
      <label class="control-label">What's on your mind?</label>
      <textarea class="form-control" rows="6" [formControl]="$any(f)"></textarea>
      <ng-container *ngIf="f.invalid">
        <span class="icon icon-warning form-control-feedback" [tooltip]="errors">
          <ng-template #errors>
            <div *ngIf="f.hasError('required')">Required</div>
            <div *ngFor="let e of f.getError('server')">{{ e }}</div>
          </ng-template>
        </span>
      </ng-container>
    </div>
  </form>
  <div class="alert alert-danger" *ngIf="formGroup.invalid && formGroup as f">
    <div *ngFor="let e of f.getError('server')">{{ e }}</div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-default btn-wide" (click)="modal.hide()">Close</button>
  <button type="submit" class="btn btn-primary btn-wide" (click)="onSubmit()">Submit</button>
</div>
