import { BsModalRef } from "ngx-bootstrap/modal"
import { Component } from "@angular/core"
import { ServiceUser } from "app/services/user"

@Component({ templateUrl: "index.html" })
export class ModalWelcomeComponent {
  autoplay = true

  muted = true

  user = this.serviceUser.current.getValue()

  video = "https://s3-us-west-2.amazonaws.com/demr-howto/referrals/welcome.mp4"

  constructor(public modal: BsModalRef, private serviceUser: ServiceUser) {}

  onClick() {
    if (this.user.show_welcome) {
      this.serviceUser.update({ show_welcome: false }).subscribe()
    }
    this.modal.hide()
  }
}
