import { ActivatedRouteSnapshot } from "@angular/router"
import { CanActivate } from "@angular/router"
import { Injectable } from "@angular/core"
import { Router } from "@angular/router"
import { RouterStateSnapshot } from "@angular/router"
import { ServiceUser } from "app/services/user"
import { UrlTree } from "@angular/router"

@Injectable({ providedIn: "root" })
export class GuardIsAuthenticated implements CanActivate {
  constructor(private router: Router, private serviceUser: ServiceUser) {}

  canActivate(_route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree {
    if (this.serviceUser.current.getValue().is_authenticated) {
      return true
    }
    return this.router.createUrlTree(["/login", { url: state.url }], { queryParamsHandling: "preserve" })
  }
}
