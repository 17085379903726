<div class="modal-body">
  <div class="sk-wave" *ngIf="loading">
    <div class="sk-wave-rect"></div>
    <div class="sk-wave-rect"></div>
    <div class="sk-wave-rect"></div>
    <div class="sk-wave-rect"></div>
    <div class="sk-wave-rect"></div>
  </div>
  <h3 class="text-center">Change your Password</h3>
  <form [formGroup]="formGroup" *ngIf="formGroup.get('password_txt') as txt">
    <div class="form-group has-feedback" *ngIf="formGroup.get('password_cur') as f" [ngClass]="{'has-error': f.invalid}">
      <label class="control-label">Current Password</label>
      <input class="form-control" [formControl]="$any(f)" [type]="txt.value ? 'text' : 'password'" />
      <ng-container *ngIf="f.invalid">
        <span class="icon icon-warning form-control-feedback" [tooltip]="errors">
          <ng-template #errors>
            <div *ngIf="f.hasError('required')">Required.</div>
            <div *ngFor="let e of f.getError('server')">{{ e }}</div>
          </ng-template>
        </span>
      </ng-container>
    </div>
    <div class="form-group">
      <div class="row">
        <div class="col-sm-6">
          <div class="form-group has-feedback" *ngIf="formGroup.get('password_new') as f" [ngClass]="{'has-error': f.invalid}">
            <label class="control-label">New Password</label>
            <input class="form-control" [formControl]="$any(f)" [type]="txt.value ? 'text' : 'password'" />
            <ng-container *ngIf="f.invalid">
              <span class="icon icon-warning form-control-feedback" [tooltip]="errors">
                <ng-template #errors>
                  <div *ngIf="f.hasError('pattern')">Not complex enough.</div>
                  <div *ngIf="f.hasError('required')">Required.</div>
                  <div *ngFor="let e of f.getError('server')">{{ e }}</div>
                </ng-template>
              </span>
            </ng-container>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="form-group has-feedback" *ngIf="formGroup.get('password_rep') as f" [ngClass]="{'has-error': f.invalid}">
            <label class="control-label">Repeat Password</label>
            <input class="form-control" [formControl]="$any(f)" type="password" />
            <ng-container *ngIf="f.invalid">
              <span class="icon icon-warning form-control-feedback" [tooltip]="errors">
                <ng-template #errors>
                  <div *ngIf="f.hasError('mismatch')">Passwords do not match.</div>
                </ng-template>
              </span>
            </ng-container>
          </div>
        </div>
      </div>
      <span class="help-block">Must be at least 8 characters long and contain at least 1 uppercase, lowercase, number, and special character.</span>
    </div>
    <div class="form-group" *ngIf="txt as f">
      <div class="checkbox custom-control custom-checkbox">
        <label>
          <input type="checkbox" [formControl]="$any(f)" />
          <span class="custom-control-indicator"></span>
          Show Passwords
        </label>
      </div>
    </div>
  </form>
  <div class="alert alert-danger" *ngIf="formGroup.hasError('server') && formGroup as f">
    <div *ngFor="let e of f.getError('server')">{{ e }}</div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-default btn-wide" (click)="modal.hide()">Close</button>
  <button type="submit" class="btn btn-primary btn-wide" (click)="onSubmit()">Submit</button>
</div>
