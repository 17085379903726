import { DatePipe } from "@angular/common"
import { Pipe } from "@angular/core"
import { PipeTransform } from "@angular/core"
import { getExtension } from "mime"

type Kind = "b64-img" | "content-type" | "date" | "file-size" | "phone-number" | "str"

@Pipe({ name: "fmt" })
export class PipeFmt implements PipeTransform {
  constructor(private datePipe: DatePipe) {}

  transform(value: any, kind?: Kind): string {
    switch (kind) {
      case "b64-img":
        if (value) {
          return value
        }
        return [
          "data:image/png;base64",
          "iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mO8+x8AAr8B3gzOjaQAAAAASUVORK5CYII=",
        ].join()
      case "content-type":
        if (value) {
          switch (getExtension(value)) {
            case "7z":
            case "bz2":
            case "tar":
            case "zip":
              return "icon-icomoon-file-zip"
            case "csv":
            case "xls":
            case "xlsx":
              return "icon-icomoon-file-excel"
            case "doc":
            case "docx":
            case "rtf":
              return "icon-icomoon-file-word"
            case "pdf":
              return "icon-icomoon-file-pdf"
            case "gif":
            case "jpeg":
            case "png":
            case "tiff":
              return "icon-icomoon-file-picture"
            case "avi":
            case "mpeg":
            case "qt":
            case "webm":
              return "icon-icomoon-file-video"
          }
        }
        return "icon-icomoon-file-text2"
      case "date":
        value = this.datePipe.transform(value, "MM/dd/yyyy")
        if (value) {
          return value
        }
        return ""
      case "file-size":
        if (value) {
          if (isNaN(value)) {
            return ""
          }
          if (value < 1) {
            return "0 B"
          }
          const idx = Math.floor(Math.log(value) / Math.log(1000))
          const units = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
          return `${(value / Math.pow(1000, idx)).toFixed(1)} ${units[idx]}`
        }
        return ""
      case "phone-number":
        if (value) {
          return value.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3")
        }
        return ""
      default:
        if (value) {
          return value
        }
        return ""
    }
  }
}
